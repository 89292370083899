import React from "react";
import { GoogleApiWrapper, Map, Marker, Polygon } from "google-maps-react";


const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '380px'
}

const MapContainer = props => {
    let _coords = [];
    if (props.value) {
        _coords = props.value.split('||').map(p => {
            const point = p.split(';;');
            return { id: Number(point[0]), order: Number(point[1]), lat: Number(point[2]), lng: Number(point[3]) }
        });
    }
    console.log(_coords);

    const addNewNode = (mapProps, map, clickEvent) => {
        const lat = clickEvent.latLng.lat();
        const lng = clickEvent.latLng.lng();
        const _c = _coords.slice()
        _c.push({ id: -1, order: 9999, lat: lat.toFixed(6), lng: lng.toFixed(6) });
        encodeAndSave(_c);
    }

    const moveNode = (node, __, dragEvent) => {
        const lat = dragEvent.latLng.lat();
        const lng = dragEvent.latLng.lng();
        const _c = _coords.slice()
        _c[node.index] = { ...node.data, lat: lat.toFixed(6), lng: lng.toFixed(6) }
        encodeAndSave(_c);
    }

    const removeNode = (node, _, event) => {
        const _c = _coords.slice()
        _c.splice(node.index, 1)
        encodeAndSave(_c);
    }

    const encodeAndSave = val => {
        const encoded = val.map(v => Object.values(v).join(';;')).join('||');
        console.log('props.value', props.value)
        console.log('encoded', encoded)
        props.onChange(encoded);
    }

    return (
        <Map google={props.google} zoom={14} containerStyle={containerStyle} initialCenter={{
            lat: -34.605613,
            lng: -58.3801271
        }}
            onClick={addNewNode}>
            <Polygon
                paths={_coords}
                strokeColor="#0000FF"
                strokeOpacity={0.6}
                strokeWeight={2}
                fillColor="#0000FF"
                fillOpacity={0.2} />
            {_coords.map((v, i) => <Marker
                onClick={removeNode}
                data={v}
                index={i}
                onDragend={moveNode}
                draggable={true}
                position={v}
                title={`Nodo ${i + 1}: ${v.lat};${v.lng}`} />
            )}
        </Map>
    )

}


const NoEnrollMap = GoogleApiWrapper({
    apiKey: 'AIzaSyA3CvTeFPuHo7ePjm5YOva66gR-pFWZvPc'
})(MapContainer);

export default NoEnrollMap;

