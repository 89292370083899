import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    hooli: yup
        .string("Seleccione un campo")
        .required("Por favor rellene este campo"),
    insurer: yup
        .string()
        .min(4, "Debe tener minimo 4 letras")
        .required("Por favor rellene este campo"),
    codigo: yup
        .string()
        .min(4, "Debe tener minimo 4 letras")
        .uppercase("Debe ser todo mayusculas")
        .required("Por favor rellene este campo"),
});

export const formikValidation = (setNewInsurer, row, src, titles) => formik({
    initialValues: {
        insurer: row && row[titles[0]] ? row[titles[0]] : "",
        codigo: row && row[titles[1]] ? row[titles[1]] : "",
        hooli: row && row.hooli ? row.hooli : "Y"
    },
    validationSchema,
    onSubmit: (values) => {
        values.photo = src;
        setNewInsurer(values)
    }
});