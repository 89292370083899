import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup
        .string()
        .min(4, "Debe tener minimo 4 letras")
        .required("Ingrese un nombre"),
    codigo: yup
        .string()
        .min(4, "Debe tener minimo 4 letras")
        .uppercase("Debe ser todo mayusculas")
        .required("Ingrese un codigo"),
    agencia: yup.string().required("Seleccione una opcion"),
    domicilio: yup.string().required("Seleccione una opcion"),
});

export const formikValidation = (setNewRubro, row, src, titles) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : "",
        codigo: row && row[titles[1]] ? row[titles[1]] : "",
        agencia: row && row.agencia ? row.agencia : "Y",
        domicilio: row && row.domicilio ? row.domicilio : "Y"
    },
    validationSchema,
    onSubmit: (values) => {
        values.photo = src;
        setNewRubro(values)
    }
});