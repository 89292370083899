import { makeStyles } from "@material-ui/core/styles";

export const SnackbarStyles = makeStyles(() => ({
    root: {
        width: '80%',
        top: '150px',
        position: 'absolute',
        '& MuiAlert-standardSuccess': {
            border: 'solid 0.1px #63d1a2'
        },
        '& .MuiAlert-standardError': {
            border: 'solid 0.1px #d44141'
        },
        '& .MuiAlert-standardWarning': {
            border: 'solid 0.1px #b4b0a3'
        }
    },
    alert: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '1rem',
        width: '100%'
    },
    message: {
        fontWeight: 400
    }
}));