import React from 'react';
import HeaderButton from "../../components/utils/button/HeaderButton";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import SubmitButton from '../../components/utils/button/SubmitButton';
import { FormikTextInput } from '../../components/utils/inputs/FormikInputs';
// assets
import borrar from "../../assets/icons/borrar.svg";
import reload from "../../assets/icons/actualizar.svg";
import lupa from "../../assets/icons/lupa.png"
// styles
import { FiltrosStyles } from '../Filtros.styles';
// recoil
import { useRecoilValue } from 'recoil';
import { rowSelected } from '../../recoilState/GlobalState';
import { useFormik } from 'formik';

export default function Filtros(props) {
    const { status } = useRecoilValue(rowSelected);

    const { filtros, setFiltros, updateList, id, cargando } = props;

    const formik = useFormik({
        initialValues: filtros,
        onSubmit: (values) => setFiltros(values)
    });

    const data = { apellido: { label: 'Apellido' }, documento: { label: 'Documento' } }
    const classes = FiltrosStyles();

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <div className={classes.inputContainer}>
                    <FormikTextInput
                        data={data}
                        name="apellido"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    {formik.values.apellido !== '' &&
                        <img
                            alt="borrar"
                            src={borrar}
                            onClick={() => formik.setFieldValue('apellido', '')}
                            className={classes.iconoBorrar}
                        />
                    }
                </div>
                <div className={classes.inputContainer}>
                    <FormikTextInput
                        data={data}
                        name="documento"
                        formik={formik}
                        disabled={cargando}
                        className={classes.input}
                    />
                    {formik.values.documento !== '' &&
                        <img
                            alt="borrar"
                            src={borrar}
                            onClick={() => formik.setFieldValue('documento', '')}
                            className={classes.iconoBorrar}
                        />
                    }
                </div>
                <div className={classes.marginNormal}>
                    <SubmitButton texto="Filtrar" />
                </div>
            </div>
            <Buttons>
                {status ?
                    <HeaderButton
                        text='Ver usuario'
                        icon={lupa}
                        data={{ action: '', procede: '' }}
                        id={id}
                    />
                    :
                    <HeaderButton
                        text="Actualizar pagina"
                        icon={reload}
                        updateList={updateList}
                    />
                }
            </Buttons>
        </form>
    )
};