import React, { useState, useEffect, useRef } from "react";
import '../form.css';
import Compress from 'compress.js'
// utils
import { Checkbox, Slider, TextField } from '@material-ui/core';
import FormButton from "../../button/FormButton";
import CommonForm from '../CommonForm';
// validation
import { FormikDateInput, FormikSelectInput, FormikTextInput, FormikYesOrNoInput } from "../../inputs/FormikInputs";
import { formikValidation } from './PreonboardingManualValidaciones';
// functions
import { localidadesGet } from "../../../../services/hooli-services/Localidades/LocalidadesGet";
import { provinciasGet } from "../../../../services/hooli-services/Localidades/ProvinciasGet";
import { usuarioFaceReg } from "../../../../services/hooli-services/IDX/UsuarioFaceReg";
import { preonboardUsuarioIns } from "../../../../services/hooli-services/Preonboard/preonboardUsuarioIns";
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, snackbarData, formData } from '../../../../recoilState/GlobalState'

export default function PreonboardingManualForm(props) {
    const [inRequest, setInRequest] = useState(false);
    const [localidades, setLocalidades] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [provinciaSeleccionada, setProvinciaSeleccionada] = useState(2);
    const [match, setMatch] = useState(60);
    const [checkboxes, setCheckboxes] = useState({ first: false, second: false, third: false, fourth: false });

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const imagenSelfie = useRef(null);
    const imagenFrenteDNI = useRef(null);
    const imagenDorsoDNI = useRef(null);
    const imagenRecorte = useRef(null);

    const { userInfo, selfie, frenteDNI, dorsoDNI, setImages, recorte, setRecorte, selfieRef, frenteRef, dorsoRef, recorteRef } = props.props;

    useEffect(() => {
        (async () => {
            const payload = userInfo.localidadId !== 1 ? {
                params: { localidadId: userInfo.localidadId },
                ...credentials
            } : { params: {}, ...credentials };
            setInRequest(true);
            const provincias = await provinciasGet(credentials);
            const localidades = await localidadesGet(payload);
            setLocalidades(localidades);
            setProvincias(provincias);
            setProvinciaSeleccionada(userInfo.localidadId !== 1 ? localidades[0].provinciaId : provincias[0].provinciaId)
            setInRequest(false);
        })();
    }, []);

    const formik = formikValidation(userInfo);
    const disabled = true;

    async function handleAction() {
        setInRequest(true);

        const compress = new Compress();
        const recorteFile = Compress.convertBase64ToFile(recorte ? recorte.split('data:image/png;base64,')[1] : '');
        const newRecorte = await compress.compress([recorteFile], { quality: 0.6 });

        const payloadIDX = {
            ...credentials,
            selfie,
            nacimiento: userInfo.usuarioFechaDeNacimiento,
            sexo: userInfo.usuarioSexo,
            documento: userInfo.usuarioNroDeDocumento
        };

        const responseIDX = await usuarioFaceReg(payloadIDX);
        console.log(responseIDX);
        if (responseIDX.status.errcode) {
            setSnackbarInfo({
                message: responseIDX.status.errmsg,
                severity: responseIDX.status.action === 'W' ? 'warning' : 'error',
                open: true
            });
            return setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
                setInRequest(false);
            }, 3000);
        }
        if (!responseIDX.result[0]) {
            setSnackbarInfo({
                message: 'IdentityX Error',
                severity: 'error',
                open: true
            });
            return setTimeout(() => {
                setSnackbarInfo({
                    message: '',
                    severity: 'success',
                    open: false
                });
                setInRequest(false);
            }, 3000);
        }
        else {
            const payload = {
                ...credentials,
                ...userInfo,
                localidadId: formik.values.localidad,
                idxId: responseIDX.result[0].href,
                documentoFrenteImg: frenteDNI,
                documentoDorsoImg: dorsoDNI,
                documentoFotoImg: newRecorte[0].data,
                usuarioFotoImg: selfie,
                usuarioDNIMatchScore: match / 100
            };
            const response = await preonboardUsuarioIns(payload);
            setInRequest(false);

            if (response.status.code === 0) {
                setSnackbarInfo({
                    message: response.status.errmsg,
                    severity: response.status.action === 'W' ? 'warning' : 'error',
                    open: true
                });
                setTimeout(() => {
                    setSnackbarInfo({
                        message: '',
                        severity: 'success',
                        open: false
                    });
                }, 3000);
            }
            else {
                setSnackbarInfo({
                    message: "Usuario añadido exitosamente",
                    severity: 'success',
                    open: true
                });
                setTimeout(() => {
                    setSnackbarInfo({
                        message: '',
                        severity: 'success',
                        open: false
                    });
                    setImages([]);
                    setRecorte('');
                    setIsOpen(false);
                }, 3000);
            }
        }
    };

    const checkboxStyle = { position: 'absolute', left: '45%', margin: '26px 0 4px -8px' };
    const secondCheckboxStyle = { position: 'absolute', right: '-8px', margin: '26px 0 4px' };

    const zoomIn = (ref) => {
        const currentZoom = ref.current.style.zoom;
        ref.current.style.zoom = currentZoom !== '' ? `${parseInt(currentZoom.split('%')[0]) + 20}%` : '120%';
    };
    const zoomOut = (e, ref) => {
        e.preventDefault();
        const currentZoom = ref.current.style.zoom;
        ref.current.style.zoom = currentZoom !== '' ? `${parseInt(currentZoom.split('%')[0]) - 20}%` : '80%';
    };

    useEffect(() => {
        if (formik.values.match < 0.4) formik.setTouched('match', true);
    }, [formik.values.match]);

    useEffect(() => {
        imagenSelfie.current.style.transform = selfieRef.current.style.transform;
        imagenFrenteDNI.current.style.transform = frenteRef.current.style.transform;
        imagenDorsoDNI.current.style.transform = dorsoRef.current.style.transform;
        imagenRecorte.current.style.transform = recorteRef.current.style.transform;
    }, [selfieRef, frenteRef, dorsoRef, recorteRef]);


    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede=""
        >
            <div className="form__image-container">
                <div className="image-with-label">
                    <img
                        src={`data:image/png;base64,${selfie}`}
                        alt="Selfie"
                        ref={imagenSelfie}
                        onClick={(e) => zoomIn(imagenSelfie)}
                        onContextMenu={(e) => zoomOut(e, imagenSelfie)}
                    />
                    <p>Selfie</p>
                </div>
                <div className="image-with-label">
                    <img
                        src={`data:image/png;base64,${frenteDNI}`}
                        alt={"Frente del DNI"}
                        ref={imagenFrenteDNI}
                        onClick={() => zoomIn(imagenFrenteDNI)}
                        onContextMenu={(e) => zoomOut(e, imagenFrenteDNI)}
                    />
                    <p>Frente del DNI</p>
                </div>
                <div className="image-with-label">
                    <img
                        src={`data:image/png;base64,${dorsoDNI}`}
                        alt="Dorso del DNI"
                        ref={imagenDorsoDNI}
                        onClick={() => zoomIn(imagenDorsoDNI)}
                        onContextMenu={(e) => zoomOut(e, imagenDorsoDNI)}
                    />
                    <p>Dorso del DNI</p>
                </div>
                <div className="image-with-label">
                    <img
                        src={recorte}
                        alt="Recorte"
                        ref={imagenRecorte}
                        onClick={() => zoomIn(imagenRecorte)}
                        onContextMenu={(e) => zoomOut(e, imagenRecorte)}
                    />
                    <p>Recorte del DNI</p>
                </div>
            </div>
            <div className="row">
                <FormikTextInput
                    data={data}
                    name="nombre"
                    formik={formik}
                    disabled={disabled}
                    className="column"
                />
                <div style={checkboxStyle}>
                    <Checkbox color="primary" checked={checkboxes.first} onChange={e => setCheckboxes({ ...checkboxes, first: e.target.checked })} />
                </div>
                <FormikDateInput
                    data={data}
                    name="nacimiento"
                    formik={formik}
                    disabled={disabled}
                    className="column"
                />
                <div style={secondCheckboxStyle}>
                    <Checkbox color="primary" checked={checkboxes.second} onChange={e => setCheckboxes({ ...checkboxes, second: e.target.checked })} />
                </div>
            </div>
            <div className="row">
                <FormikTextInput
                    data={data}
                    name="tramite"
                    formik={formik}
                    disabled={disabled}
                    className="column"
                />
                <div style={checkboxStyle}>
                    <Checkbox color="primary" checked={checkboxes.third} onChange={e => setCheckboxes({ ...checkboxes, third: e.target.checked })} />
                </div>
                <FormikDateInput
                    data={data}
                    name="vencimientoDNI"
                    formik={formik}
                    disabled={disabled}
                    className="column"
                />
                <div style={secondCheckboxStyle}>
                    <Checkbox color="primary" checked={checkboxes.fourth} onChange={e => setCheckboxes({ ...checkboxes, fourth: e.target.checked })} />
                </div>
            </div>
            <div className="row">
                <div className="row column">
                    <FormikTextInput
                        data={data}
                        name="dni"
                        formik={formik}
                        disabled={disabled}
                        className="column"
                    />
                    <FormikTextInput
                        data={data}
                        name="sexo"
                        formik={formik}
                        disabled={disabled}
                        className="column"
                    />
                </div>
                <div className="row column">
                    <FormikTextInput
                        data={data}
                        name="cuit"
                        formik={formik}
                        disabled={disabled}
                        className="column"
                    />
                    <FormikTextInput
                        data={data}
                        name="match"
                        formik={formik}
                        disabled={disabled}
                        className="column"
                    />
                </div>
            </div>
            <div className="row">
                <TextField
                    label={data.provincia.label}
                    name="provincia"
                    margin="normal"
                    select
                    disabled={userInfo.localidadId !== 1 || inRequest}
                    SelectProps={{ native: true }}
                    value={provinciaSeleccionada}
                    onChange={(e) => setProvinciaSeleccionada(parseInt(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                    className="column"
                >
                    {provincias.map(option => (
                        <option key={option.provinciaId} value={option.provinciaId}>
                            {option.provinciaNombre}
                        </option>
                    ))}
                </TextField>
                <FormikSelectInput
                    data={data}
                    name="localidad"
                    formik={formik}
                    disabled={userInfo.localidadId !== 1 || inRequest}
                    options={localidades.filter(l => l.provinciaId === provinciaSeleccionada)}
                    optionKey="localidadId"
                    optionValue="localidadId"
                    optionName="localidadNombre"
                    className="column"
                />
            </div>
            <div className="row">
                <div className="row column">
                    <FormikTextInput
                        data={data}
                        name="calle"
                        formik={formik}
                        disabled={disabled}
                        className="column"
                    />
                    <FormikTextInput
                        data={data}
                        name="numero"
                        formik={formik}
                        disabled={disabled}
                        className="column"
                    />
                </div>
                <div className="row column">
                    <FormikTextInput
                        data={data}
                        name="piso"
                        formik={formik}
                        disabled={disabled}
                        className="column"
                    />
                    <FormikTextInput
                        data={data}
                        name="CP"
                        formik={formik}
                        disabled={disabled}
                        className="column"
                    />
                </div>
            </div>
            <div className="slider-container">
                <label>Match estimado entre selfie y DNI</label>
                <Slider
                    aria-label="Match estimado entre selfie y DNI"
                    defaultValue={80}
                    min={60}
                    max={100}
                    step={1}
                    value={match}
                    onChange={(e, value) => setMatch(value)}
                    valueLabelDisplay="auto"
                    style={{ maxWidth: '45%' }}
                />
            </div>
            <FormButton
                inRequest={inRequest}
                newDisabled={Object.values(checkboxes).some(key => !key)}
                handleAction={handleAction}
            />
        </CommonForm >
    );
};