import { fallbackURL } from "../../consts/FallbackURL";

export const checkAuth = () => {
    if (localStorage.getItem('hooliToken') && localStorage.getItem('sessionId')) {
        return {
            hooliToken: localStorage.getItem('hooliToken'),
            sessionId: localStorage.getItem('sessionId')
        }
    };

    if (window.location.search) {
        const data = window.location.search.split("&");
        if (data) {
            localStorage.setItem('sessionId', data[0].split("=")[1]);
            localStorage.setItem('hooliToken', data[1].split("=")[1]);

            return {
                hooliToken: localStorage.getItem('hooliToken'),
                sessionId: localStorage.getItem('sessionId')
            }
        }
    }

    return window.location.assign(fallbackURL);
};