import { MenuItem } from "@material-ui/core";
import React from "react";
import usePlacesAutocomplete, {
    getGeocode,
} from "use-places-autocomplete";
import classes from './LocationModal.module.css';

export default function SearchBox({ setCoordenadas }) {
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300
    });

    const handleInput = e => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };

    const handleSelect = ({ description }) => async () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        const results = await getGeocode({ address: description });
        setCoordenadas({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
        });
    };

    const renderSuggestions = () =>
        data.map((suggestion, index) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text }
            } = suggestion;
            if (index < 3) {
                return (
                    <MenuItem
                        key={place_id}
                        onClick={handleSelect(suggestion)}
                        style={{ fontFamily: 'Roboto', listStyleType: 'none' }}
                    >
                        <strong>{main_text}, </strong>&nbsp;<small> {secondary_text}</small>
                    </MenuItem>
                );
            }
            return
        });

    return (
        <div className={classes.searchBoxContainer}>
            <input
                value={value}
                onChange={handleInput}
                disabled={!ready}
                className={classes.searchBox}
                placeholder="Placeholder"
            />
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            {status === "OK" && <ul style={{ paddingLeft: '0' }}>{renderSuggestions()}</ul>}
        </div>
    );
}