// OnBoarding
export const endUser = ["Apellidos", "Nombres", "Tipo", "Documento", "Sexo", "Fecha de registro", "CVU", "Alias", "Status de registro"];
export const merchant = ["Nombre fantasía", "Razón Social", "CUIT", "Persona F / J", "Fecha de alta", "Rubro", "Plan", "Apellidos", "Nombres", "DNI"];

// Usuarios
export const usuarios = ["Apellidos", "Nombres", "Tipo", "Documento", "Sexo", "Fecha de registro"];
export const busquedaUsuarios = ["Apellidos", "Nombres", "Tipo", "Documento", "Sexo", "Email", "Telefono", "CVU", "Fecha de registro"]; 

// Tablas
export const aseguradoras = ["Nombre de la aseguradora", "Código", "Hooli", "Logo"];
export const localidades = ["Nombre"];
export const condicionesAFIP = ["Nombre", "Codigo", "Nombre AFIP"];
export const tiposDeDomicilio = ["Domicilio", "Codigo"];
export const tiposDeImagen = ["Nombre", "Codigo", "Bucket", "Folder"];
export const formatosDeImagen = ["Nombre", "Codigo", "Ancho", "Alto"];
export const formatosXTipoDeImagen = ["Tipo de imagen", "Formato de imagen", "Ancho", "Alto", "Bucket", "Folder"];
export const monedas = ["Nombre", "Codigo", "Es base"];
export const cotizacionesDeMonedas = ["Fecha", "Cantidad que cotiza", "Valor comprador", "Valor vendedor"];
export const feriados = ["Nombre"];
export const seriesDeFeriados = ["Fecha", "Comercial", "Bancario"];
export const registros = ["Nombre", "Codigo", "Descripción", "BO"];
export const tiposDeRegistro = ["Nombre", "Codigo"];
export const estadosDeRegistro = ["Nombre", "Codigo"];
export const eventosDeSesion = ["Nombre", "Codigo"];
export const devices = [""];
export const tiposDeDevice = ["Nombre", "Codigo"];
export const zonasDeNoEnroll = ["Nombre"];
export const merchants = ["Nombre", "CUIT", "Plan", "Rubro", "Acreditacion"];
export const rubrosDeMerchant = ["Nombre", "Codigo", "Agencia", "A domicilio"];
export const cargos = ["Nombre", "Codigo"];