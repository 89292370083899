// services
import { tiposDeDomicilioIns } from '../../../../services/hooli-services/TiposDeDomicilio/TiposDeDomicilioIns';
import { tiposDeDomicilioDel } from '../../../../services/hooli-services/TiposDeDomicilio/TiposDeDomicilioDel';
import { tiposDeDomicilioUpd } from '../../../../services/hooli-services/TiposDeDomicilio/TiposDeDomicilioUpd';

export async function postTipoDeDomicilio(credentials, newTipoDeDomicilio, setInRequest) {
    if (Object.keys(newTipoDeDomicilio).length > 0) {
        const payload = { ...newTipoDeDomicilio, ...credentials };

        setInRequest(true);
        const response = await tiposDeDomicilioIns(payload);
        return response
    }
};

export async function deleteTipoDeDomicilio(credentials, row, setInRequest) {
    const { id } = row
    const payload = { id, ...credentials }

    setInRequest(true);
    const response = await tiposDeDomicilioDel(payload);
    return response
};

export async function updateTipoDeDomicilio(credentials, newTipoDeDomicilio, row, setInRequest) {
    if (Object.keys(newTipoDeDomicilio).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newTipoDeDomicilio,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await tiposDeDomicilioUpd(payload)
        return response
    }
};