import axiosPost from '../axiosPost';

export const aseguradoraIns = async (arg) => {
    const { insurer, codigo, hooli, photo } = arg;

    const body = {
        "service": "AseguradoraIns",
        "params": {
            "aseguradoraNombre": insurer,
            "aseguradoraCod": codigo,
            "aseguradoraHooli": hooli,
            "aseguradoraImg": photo
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }

}