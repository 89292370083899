import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup.string().required("Ingrese un nombre"),
    codigo: yup.string().max(6, "Debe tener maximo 6 letras").uppercase('Debe ser todo mayusculas').required("Ingrese un codigo"),
    ancho: yup.number().positive("Ingrese un numero mayor a 0").required("Ingrese un ancho"),
    alto: yup.number().positive("Ingrese un numero mayor a 0").required("Ingrese un alto"),
});

export const formikValidation = (setNewFormato, row, titles) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : '',
        codigo: row && row[titles[1]] ? row[titles[1]] : '',
        ancho: row && row[titles[2]] ? row[titles[2]] : '',
        alto: row && row[titles[3]] ? row[titles[3]] : ''
    },
    validationSchema,
    onSubmit: (values) => setNewFormato(values)
});