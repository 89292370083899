import axiosPost from '../axiosPost';

export const tiposDeImagenDel = async (arg) => {
    const { id } = arg;

    const body = {
        "service": "TipoDeImagenDel",
        "params": {
            "tipoDeImagenId": id
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}