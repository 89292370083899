import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    cantidad: yup.string().required("Ingrese una cantidad"),
    moneda: yup.string().required("Selecciona una moneda"),
    fecha: yup.string().required("Selecciona una fecha"),
    comprador: yup.number().positive("Ingrese un valor mayor a 0").required("Ingrese un valor"),
    vendedor: yup.number().positive("Ingrese un valor mayor a 0").required("Ingrese un valor")
});

export const formikValidation = (setNewCotizacion, row, titles) => formik({
    initialValues: {
        cantidad: row && row[titles[0]] ? row[titles[0]] : "",
        moneda: row && row[titles[1]] ? row[titles[1]] : 1,
        fecha: row && row[titles[2]] ? row[titles[2]] : new Date().toISOString().substring(0, 10),
        comprador: row && row[titles[3]] ? row[titles[3]] : "",
        vendedor: row && row[titles[4]] ? row[titles[4]] : "",
    },
    validationSchema,
    onSubmit: (values) => setNewCotizacion(values)
});