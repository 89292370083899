import React from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const containerStyle = {
    flexGrow: 1,
    height: '300px'
};
const libraries = ["places"]
export function Mapa(props) {
    const { lat, lng } = props;
    const center = {
        lat: lat ? typeof lat === "number" ? lat : parseFloat(lat) : -34.6036844,
        lng: lng ? typeof lng === "number" ? lng : parseFloat(lng) : -58.3815591
    };

    return (

        <LoadScript
            id="google-map-script"
            googleMapsApiKey="AIzaSyA3CvTeFPuHo7ePjm5YOva66gR-pFWZvPc"
            libraries={libraries}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={14}
            >
                <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    )
}