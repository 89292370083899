import axiosPost from '../axiosPost';

export const renaperPreonboardCheck = async (arg) => {
    const { documento, sexo, recorte } = arg;
    const body = {
        "service": "RENAPERPreOnboardCheck",
        "params": {
            "usuarioNroDeDocumento": documento,
            "usuarioSexo": sexo,
            "documentoFotoImg": recorte
        }
    };

    try {
        const result = await axiosPost('Y', body, arg);
        return result.data
    } catch (err) {
        console.log(err)
    }
};