import axiosPost from '../axiosPost';

export const cargosIns = async (arg) => {
    const { nombre, codigo } = arg;

    const body = {
        "service": "CargoIns",
        "params": {
            "cargoNombre": nombre,
            "cargoCod": codigo
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}