import axiosPost from '../axiosPost';

export const condicionesAFIPUpd = async (arg) => {
    const { id, nombre, codigo, nombreAFIP } = arg;

    const body = {
        "service": "CondicionAFIPIns",
        "params": {
            "condicionAFIPId": id,
            "condicionAFIPNombre": nombre,
            "condicionAFIPCodigo": codigo,
            "condicionAFIPNombreAFIP": nombreAFIP,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    };
}