// services
import { estadosDeRegistroIns } from '../../../../services/hooli-services/EstadosDeRegistro/EstadosDeRegistroIns';
import { estadosDeRegistroDel } from '../../../../services/hooli-services/EstadosDeRegistro/EstadosDeRegistroDel';
import { estadosDeRegistroUpd } from '../../../../services/hooli-services/EstadosDeRegistro/EstadosDeRegistroUpd';

export async function postEstadoDeRegistro(credentials, newEstadoDeRegistro, setInRequest) {
    if (Object.keys(newEstadoDeRegistro).length > 0) {
        const payload = {
            ...newEstadoDeRegistro,
            ...credentials
        };

        setInRequest(true);
        const response = await estadosDeRegistroIns(payload);
        return response
    }
};

export async function deleteEstadoDeRegistro(credentials, row, setInRequest) {
    const { id } = row

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await estadosDeRegistroDel(payload);
    return response
};

export async function updateEstadoDeRegistro(credentials, newEstadoDeRegistro, row, setInRequest) {
    if (Object.keys(newEstadoDeRegistro).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newEstadoDeRegistro,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await estadosDeRegistroUpd(payload);
        return response
    }
};