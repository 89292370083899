import React, { useState, useEffect, useRef } from "react";
import '../form.css';
// assets
import upload from "../../../../assets/icons/upload.svg";
import recbin from '../../../../assets/icons/recbin.svg';
// utils
import CircularButton from '../../button/HeaderButton';
import FormButton from "../../button/FormButton";
import CommonForm from '../CommonForm';
// validation
import { formikValidation } from './AseguradorasFormValidaciones';
// functions
import { postAnAseguradora, deleteAseguradora, updateAseguradora } from './AseguradorasFunctions';
import { generateImage, handleImg, handleResponse } from "../../../../services/Local-services";
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState'
import { FormikTextInput, FormikYesOrNoInput } from "../../inputs/FormikInputs";

export default function AseguradorasForm(props) {
    const [newAseguradora, setNewAseguradora] = useState({});
    const [{ alt, src }, setImg] = useState({
        src: null,
        alt: '',
    });
    const [newImage, setNewImage] = useState('');
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, procede } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        if (procede === 'Deleteone') {
            const response = await deleteAseguradora(credentials, selected.row, setInRequest);
            return handleResponse(response, 'Aseguradora eliminada exitosamente', functions);
        }
        if (procede === 'Addone') {
            const response = await postAnAseguradora(credentials, newAseguradora, newImage, setInRequest)
            return handleResponse(response, 'Aseguradora añadida exitosamente', functions);
        }
        if (procede === 'Editone') {
            const response = await updateAseguradora(credentials, newAseguradora, selected.row, newImage, src, setInRequest);
            return handleResponse(response, 'Aseguradora modificada exitosamente', functions);
        }
    };

    useEffect(() => {
        if (procede !== 'Deleteone') handleAction();
    }, [newAseguradora])

    const readerHandler = (readerEvt) => {
        let binaryString = readerEvt.target.result
        setImg({ src: btoa(binaryString) })
        setNewImage(btoa(binaryString))
    };

    const formik = formikValidation(setNewAseguradora, selected.row, src, titles);
    const disabled = inRequest || procede === 'Deleteone'

    const inputFile = useRef(null);
    const onButtonClick = () => inputFile.current.click();

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikTextInput
                data={data}
                name="insurer"
                formik={formik}
                disabled={disabled}
            />
            <FormikTextInput
                data={data}
                name="codigo"
                formik={formik}
                disabled={disabled}
            />
            <FormikYesOrNoInput
                data={data}
                name="hooli"
                formik={formik}
                disabled={disabled}
            />
            {generateImage(selected, 'imagePathASEGURA', src, alt)}
            <div className="form__button-container">
                <div className="form__button" style={{ border: 'solid 1px #63C3D1' }}>
                    <input
                        style={{ display: "none" }}
                        accept=".png"
                        ref={inputFile}
                        onChange={(e) => handleImg(e, setImg, readerHandler)}
                        type="file"
                    />
                    <div className="form__button" onClick={onButtonClick}>
                        <CircularButton
                            text={newImage || (selected.row && selected.row.imagePathASEGURA) ? "Reemplazar imagen" : 'Añadir imagen'}
                            icon={upload}
                        />
                    </div>
                </div>
                {newImage || (selected.row && selected.row.imagePathASEGURA) ?
                    <div className="form__button" style={{ border: 'solid 1px #D44141' }}>
                        <CircularButton
                            text={"Eliminar imagen"}
                            icon={recbin}
                            deleteImage={() => {
                                setNewImage(null)
                                setImg({ src: null })
                                if (selected.row) {
                                    setSelected({ ...selected, row: { ...selected.row, imagePathASEGURA: '' } })
                                }
                            }}
                        />
                    </div>
                    : null}
            </div>
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};