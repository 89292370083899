import axiosPost from '../axiosPost';

export const formatosDeImagenIns = async (arg) => {
    const { nombre, codigo, ancho, alto } = arg;

    const body = {
        "service": "FormatoDeImagenIns",
        "params": {
            "formatoDeImagenNombre": nombre,
            "formatoDeImagenCod": codigo,
            "formatoDeImagenAncho": ancho,
            "formatoDeImagenAlto": alto
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}