// services
import { feriadosIns } from '../../../../services/hooli-services/Feriados/FeriadosIns';
import { feriadosDel } from '../../../../services/hooli-services/Feriados/FeriadosDel';
import { feriadosUpd } from '../../../../services/hooli-services/Feriados/FeriadosUpd';

export async function postFeriado(credentials, newFeriado, setInRequest) {
    if (Object.keys(newFeriado).length > 0) {
        const payload = { ...credentials, ...newFeriado };

        setInRequest(true);
        const response = await feriadosIns(payload);
        return response
    }
};

export async function deleteFeriado(credentials, row, setInRequest) {
    const { id } = row
    const payload = { id, ...credentials }

    setInRequest(true);
    const response = await feriadosDel(payload);
    return response
};

export async function updateFeriado(credentials, newFeriado, row, setInRequest) {
    if (Object.keys(newFeriado).length > 0) {
        const { nombre } = newFeriado;
        const { id, version } = row;

        const payload = {
            id,
            nombre,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await feriadosUpd(payload)
        return response
    }
};