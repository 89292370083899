import React, { useState, useEffect } from "react";
// components
import TransitionModal from "../../components/utils/modals/Modals";
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import XGridDemo from "../../components/utils/grid/Xgrid";
// consts
import { monedas as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoMonedas as infoModal } from "../../consts/Modals-info";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { monedasGet } from '../../services/hooli-services/Monedas/MonedasGet';
// recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { userCredentials, rowSelected, formData } from '../../recoilState/GlobalState';

export default function Monedas() {
    const [monedas, setMonedas] = useState([]);
    const [rows, setRows] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [action, setAction] = useState({});

    const [{ status }, setRow] = useRecoilState(rowSelected)
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData);

    const updateList = async () => {
        setCargando(true);
        await retrieveData(credentials, setRows, monedasGet, setMonedas);
        setCargando(false);
    }

    useEffect(() => {
        if (Object.keys(credentials).length > 0) updateList()
    }, [credentials]);

    useEffect(() => {
        monedas.map((item) => {
            const {
                monedaId,
                monedaNombre,
                monedaCod,
                monedaEsBase,
                monedaVersion,
            } = item

            const moneda = {
                id: monedaId,
                version: monedaVersion,
                esBase: monedaEsBase,
                [titles[0]]: monedaNombre,
                [titles[1]]: monedaCod,
                [titles[2]]: monedaEsBase === 'Y' ? 'Si' : 'No',
            }

            setRows(rows => [...rows, moneda])
            return item
        });
    }, [monedas]);

    useEffect(() => setRow({ status: false }), [])

    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action]);
        setAction({ action, procede });
    };

    return (
        <main className="container">
            <TransitionModal
                titles={titles}
                procede={action.procede}
                updateList={updateList}
            />
            <Buttons texto="moneda" handleClick={handleClick} updateList={updateList} />
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};