import React, { useState, useEffect } from 'react'
import classes from './LocationModal.module.css'
import Button from "@material-ui/core/Button";
import CircularIndeterminate from '../../../components/utils/spinner/Spinner';
import { Mapa } from './Mapa';
import { TextField } from '@material-ui/core';
import TransitionModal from '../../../components/utils/modals/Modals';
// Servicios
import { retrieveData } from '../../../services/Local-services';
import { endUserLocationGet } from '../../../services/hooli-services/EndUser/EndUserLocationGet';
import { endUserLocationUpd } from '../../../services/hooli-services/EndUser/EndUserLocationUpd';
import { provinciasGet } from '../../../services/hooli-services/Localidades/ProvinciasGet';
import { localidadesGet } from '../../../services/hooli-services/Localidades/LocalidadesGet';
// Recoil
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { userCredentials, rowSelected, snackbarData } from '../../../recoilState/GlobalState';

export default function LocationModal(props) {
    const credentials = useRecoilValue(userCredentials);

    const [userInfo, setUserInfo] = useState({});
    const [provincias, setProvincias] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [modalMapaOpen, setModalMapaOpen] = useState(false);
    const [cargando, setCargando] = useState(true);
    const [selectDisabled, setSelectDisabled] = useState(true);
    const [inRequest, setInRequest] = useState(false);

    const selected = useRecoilValue(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const { setProcede, updateModalList } = props.props;

    async function retrieveUserInfo() {
        const params = { "usuarioId": selected.row.id }
        setCargando(true);
        const payload = { ...credentials, params };
        const data = await endUserLocationGet(payload)
        setCargando(false);
        setUserInfo(data);
    };

    async function retrieveTowns() {
        const params = { "provinciaId": userInfo.provinciaId }
        setSelectDisabled(true);
        const payload = { ...credentials, params };
        const data = await localidadesGet(payload)
        setSelectDisabled(false);
        setLocalidades(data)
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) {
            retrieveUserInfo()
            retrieveData(credentials, undefined, provinciasGet, setProvincias)
        }
    }, [credentials]);

    useEffect(() => {
        if (credentials && userInfo.provinciaId) retrieveTowns()
    }, [credentials, userInfo ? userInfo.provinciaId : '', provincias]);

    const handleClick = async (estadoDeRegistro) => {
        const { domicilioCPA, domicilioCalle, domicilioCasa, domicilioOtros, domicilioPiso, domicilioUnidad, domicilioXUsuarioId, domicilioXUsuarioVersion, domicilioLongitud, domicilioLatitud, localidadId } = userInfo

        const params = {
            "domicilioXUsuarioId": domicilioXUsuarioId,
            "estadoDeRegistroCod": estadoDeRegistro,
            "domicilioCalle": domicilioCalle,
            "domicilioCasa": domicilioCasa,
            "domicilioPiso": domicilioPiso,
            "domicilioUnidad": domicilioUnidad,
            "domicilioCPA": domicilioCPA,
            "domicilioOtros": domicilioOtros,
            "domicilioLongitud": parseFloat(domicilioLongitud).toFixed(6),
            "domicilioLatitud": parseFloat(domicilioLatitud).toFixed(6),
            "localidadId": localidadId,
            "domicilioXUsuarioVersion": domicilioXUsuarioVersion,
        };

        const payload = { ...credentials, params };
        setInRequest(true)
        const response = await endUserLocationUpd(payload)
        if (response) {
            if (response.status.code === 1) {
                setSnackbarInfo({
                    message: 'Ubicacion guardada',
                    severity: 'success',
                    open: true
                });
                setInRequest(false);
                setTimeout(() => {
                    setSnackbarInfo({
                        message: '',
                        severity: 'success',
                        open: false
                    });
                    setProcede(undefined);
                    updateModalList();
                }, 3000)
            }
            else {
                setSnackbarInfo({
                    message: response.status.errmsg,
                    severity: response.status.action === 'W' ? 'warning' : 'error',
                    open: true
                });
                setInRequest(false);
                setTimeout(() => {
                    setSnackbarInfo({
                        message: '',
                        severity: 'success',
                        open: false
                    });
                }, 3000);
            }
        }
    };

    return (
        cargando ? <CircularIndeterminate /> :
            <div className={classes.container}>
                {modalMapaOpen && <TransitionModal
                    procede="location-mapa"
                    setSecondaryOpen={setModalMapaOpen}
                    setUserInfo={setUserInfo}
                    userInfo={userInfo}
                    lat={userInfo ? userInfo.domicilioLatitud : null}
                    lng={userInfo ? userInfo.domicilioLongitud : null}
                />}
                <h2 className={classes.title}>Datos de locación</h2>
                <form>
                    <TextField
                        label='Domicilio Renaper'
                        name='renaper'
                        placeholder=''
                        value={userInfo.domicilioScan}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                    <div className={classes.row}>
                        <TextField
                            label='Calle'
                            name='calle'
                            placeholder=''
                            className={classes.inputBig}
                            margin="normal"
                            value={userInfo ? userInfo.domicilioCalle : ''}
                            onChange={(e) => setUserInfo({ ...userInfo, domicilioCalle: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                        />
                        <div className={`${classes.row} ${classes.inputBig}`}>
                            <TextField
                                label='Nro'
                                name='numero'
                                placeholder=''
                                className={classes.column}
                                margin="normal"
                                value={userInfo ? userInfo.domicilioCasa : ''}
                                onChange={(e) => setUserInfo({ ...userInfo, domicilioCasa: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label='Piso'
                                name='piso'
                                placeholder=''
                                className={classes.column}
                                margin="normal"
                                value={userInfo ? userInfo.domicilioPiso : ''}
                                onChange={(e) => setUserInfo({ ...userInfo, domicilioPiso: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <TextField
                            label='Depto'
                            name='depto'
                            placeholder=''
                            className={classes.inputSm}
                            margin="normal"
                            value={userInfo ? userInfo.domicilioDepto : ''}
                            onChange={(e) => setUserInfo({ ...userInfo, domicilioDepto: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label='CP'
                            name='cp'
                            placeholder=''
                            className={classes.inputSm}
                            margin="normal"
                            value={userInfo ? userInfo.domicilioCPA : ''}
                            onChange={(e) => setUserInfo({ ...userInfo, domicilioCPA: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className={classes.row}>
                        <TextField
                            label='Provincia'
                            name='provincia'
                            placeholder=''
                            className={classes.inputBig}
                            margin="normal"
                            select
                            SelectProps={{ native: true }}
                            value={userInfo ? userInfo.provinciaId : ''}
                            onChange={(e) => setUserInfo({ ...userInfo, provinciaId: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                        >
                            {provincias.map(p => {
                                return <option key={p.provinciaId} value={p.provinciaId}>
                                    {p.provinciaNombre}
                                </option>
                            })}
                        </TextField>
                        <TextField
                            label='Localidad'
                            name='localidad'
                            className={classes.inputBig}
                            margin="normal"
                            select
                            disabled={selectDisabled}
                            SelectProps={{ native: true }}
                            value={userInfo ? userInfo.localidadId : ''}
                            onChange={(e) => setUserInfo({ ...userInfo, localidadId: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                        >
                            {localidades.map(l => {
                                return <option key={l.localidadId} value={l.localidadId}>
                                    {l.localidadNombre}
                                </option>
                            })}
                        </TextField>
                        <TextField
                            label='Latitud'
                            name='latitud'
                            placeholder=''
                            className={classes.inputSm}
                            margin="normal"
                            disabled
                            value={userInfo ? userInfo.domicilioLatitud : ''}
                            onChange={(e) => setUserInfo({ ...userInfo, domicilioLatitud: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label='Longitud'
                            name='longitud'
                            placeholder=''
                            className={classes.inputSm}
                            margin="normal"
                            disabled
                            value={userInfo ? userInfo.domicilioLongitud : ''}
                            onChange={(e) => setUserInfo({ ...userInfo, domicilioLongitud: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    <div className={classes.row}>
                        <div className={classes.mapa}>
                            {!modalMapaOpen &&
                                <Mapa
                                    lat={userInfo ? userInfo.domicilioLatitud : null}
                                    lng={userInfo ? userInfo.domicilioLongitud : null}
                                    setUserInfo={setUserInfo}
                                    userInfo={userInfo}
                                />
                            }
                        </div>
                        <Button className={classes.buttonCoordenadas} onClick={() => setModalMapaOpen(true)}>
                            Buscar coordenadas
                        </Button>
                        <div className={classes.buttons}>
                            <TextField
                                label='Otros'
                                name='otros'
                                placeholder=''
                                margin="normal"
                                style={{ marginLeft: '1rem' }}
                                value={userInfo && userInfo.domicilioOtros ? userInfo.domicilioOtros : ''}
                                onChange={(e) => setUserInfo({ ...userInfo, domicilioOtros: e.target.value })}
                                InputLabelProps={{ shrink: true }}
                                multiline={true}
                                fullWidth
                            />
                            <div>
                                <div className={classes.buttonRow}>
                                    <Button disabled={inRequest} className={classes.button} onClick={() => handleClick('A')}>
                                        Confirmar
                                    </Button>
                                    <Button disabled={inRequest} className={classes.button} >
                                        Derivar
                                    </Button>
                                </div>
                                <div className={classes.buttonRow}>
                                    <Button disabled={inRequest} className={classes.button} onClick={() => handleClick('R')}>
                                        Rechazar
                                    </Button>
                                    <Button
                                        disabled={inRequest} className={`${classes.button} ${classes.closeButton}`}
                                        onClick={() => setProcede(undefined)}
                                    >
                                        <span>Cerrar</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
    )
}