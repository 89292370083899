import React, { useState, useEffect } from "react";
// components
import Filtros from './Filtros';
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
// consts
import { localidades as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoLocalidad as infoModal } from "../../consts/Modals-info";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { provinciasGet } from '../../services/hooli-services/Localidades/ProvinciasGet';
import { localidadesGet } from '../../services/hooli-services/Localidades/LocalidadesGet';
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, rowSelected, formData } from '../../recoilState/GlobalState';

export default function Localidades() {
    const [localidades, setLocalidades] = useState([]);
    const [rows, setRows] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [provinciaSeleccionada, setProvinciaSeleccionada] = useState(1);
    const [action, setAction] = useState({});
    const [cargando, setCargando] = useState(true);

    const setRow = useSetRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData);

    async function retrieveTowns() {
        const params = provinciaSeleccionada ? { "provinciaId": provinciaSeleccionada } : {}

        const payload = { ...credentials, params };
        setRows([]);
        setCargando(true);

        const data = await localidadesGet(payload)
        setCargando(false);
        setLocalidades(data)
        setRow({ status: false });
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveData(credentials, undefined, provinciasGet, setProvincias)
    }, [credentials])

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveTowns()
    }, [credentials, provinciaSeleccionada]);

    useEffect(() => {
        localidades.map((item) => {
            const {
                localidadId,
                localidadVersion,
                localidadNombre,
                provinciaId,
                provinciaNombre
            } = item

            const localidad = {
                id: localidadId,
                version: localidadVersion,
                provinciaId,
                provinciaNombre,
                [titles[0]]: localidadNombre
            }

            setRows(rows => [...rows, localidad])
            return item
        });
    }, [localidades]);

    useEffect(() => setRow({ status: false }), [])

    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action]);
        setAction({ action, procede });
    };

    return (
        <main className="container">
            <TransitionModal
                titles={titles}
                procede={action.procede}
                provincias={provincias}
                provinciaSeleccionada={provinciaSeleccionada}
                setProvinciaSeleccionada={setProvinciaSeleccionada}
                updateList={retrieveTowns}
            />
            <Filtros
                provincias={provincias}
                provinciaSeleccionada={provinciaSeleccionada}
                setProvinciaSeleccionada={setProvinciaSeleccionada}
                handleClick={handleClick}
                cargando={cargando}
                updateList={retrieveTowns}
            />
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};