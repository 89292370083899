import axiosPost from '../axiosPost';

export const registrosUpd = async (arg) => {
    const { id, nombre, codigo, descripcion, bo, version, tipoDeRegistro } = arg;

    const body = {
        "service": "RegistroUpd",
        "params": {
            'registroId': id,
            "registroNombre": nombre,
            "registroCod": codigo,
            "registroDescripcion": descripcion,
            "registroRequiereBO": bo,
            "registroVersion": version,
            "tipoRegistroId": tipoDeRegistro
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}