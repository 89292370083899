import React, { useState, useEffect } from "react";
// utils
import FormButton from "../../button/FormButton";
import CommonForm from "../CommonForm";
import { FormikSelectInput, FormikTextInput, FormikYesOrNoInput } from "../../inputs/FormikInputs";
// validation
import { formikValidation } from './RegistrosFormValidaciones';
import { handleResponse } from "../../../../services/Local-services";
// functions
import { postAnRegistro, deleteRegistro, updateRegistro } from './RegistrosFunctions';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState';

export default function RegistrosForm(props) {
    const [newRegistro, setNewRegistro] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, procede, tiposDeRegistro } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        if (procede === 'Deleteone') {
            const response = await deleteRegistro(credentials, selected.row, setInRequest);
            return handleResponse(response, 'Registro eliminado exitosamente', functions);
        }
        if (procede === 'Addone') {
            const response = await postAnRegistro(credentials, newRegistro, setInRequest)
            return handleResponse(response, 'Registro añadido exitosamente', functions);
        }
        if (procede === 'Editone') {
            const response = await updateRegistro(credentials, newRegistro, selected.row, setInRequest);
            return handleResponse(response, 'Registro modificado exitosamente', functions);
        }
    };

    useEffect(() => {
        if (procede !== 'Deleteone') handleAction();
    }, [newRegistro])

    const formik = formikValidation(setNewRegistro, selected.row, titles, tiposDeRegistro);
    const disabled = inRequest || procede === 'Deleteone';

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikSelectInput
                data={data}
                name="tipoDeRegistro"
                formik={formik}
                disabled={procede === 'Addone' ? inRequest : true}
                options={tiposDeRegistro}
                optionKey="tipoRegistroId"
                optionValue="tipoRegistroId"
                optionName="tipoRegistroNombre"
            />
            <FormikTextInput
                data={data}
                name="nombre"
                formik={formik}
                disabled={disabled}
            />
            <FormikTextInput
                data={data}
                name="codigo"
                formik={formik}
                disabled={disabled}
            />
            <FormikTextInput
                data={data}
                name="descripcion"
                formik={formik}
                disabled={disabled}
            />
            <FormikYesOrNoInput
                data={data}
                name="bo"
                formik={formik}
                disabled={disabled}
            />
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};