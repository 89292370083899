// services
import { serieDeFeriadosIns } from '../../../../services/hooli-services/SerieDeFeriados/SerieDeFeriadosIns';
import { serieDeFeriadosDel } from '../../../../services/hooli-services/SerieDeFeriados/SerieDeFeriadosDel';
import { serieDeFeriadosUpd } from '../../../../services/hooli-services/SerieDeFeriados/SerieDeFeriadosUpd';

export async function postSerie(credentials, newSerie, feriadoSeleccionado, setInRequest) {
    if (Object.keys(newSerie).length > 0) {
        const payload = {
            ...newSerie,
            feriadoSeleccionado,
            ...credentials
        };

        setInRequest(true);
        const response = await serieDeFeriadosIns(payload);
        return response
    }
};

export async function deleteSerie(credentials, row, setInRequest) {
    const { id } = row
    const payload = { id, ...credentials }

    setInRequest(true);
    const response = await serieDeFeriadosDel(payload);
    return response
};

export async function updateSerie(credentials, newSerie, row, setInRequest) {
    if (Object.keys(newSerie).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newSerie,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await serieDeFeriadosUpd(payload)
        return response
    }
};