import axiosPost from '../axiosPost';

export const rubrosDeMerchantIns = async (arg) => {
    const { nombre, codigo, agencia, domicilio, imagen } = arg;

    const body = {
        "service": "RubroDeMerchantIns",
        "params": {
            "rubroMerchantNombre": nombre,
            "rubroMerchantCod": codigo,
            "rubroMerchantEsAgencia": agencia,
            "rubroMerchantADomicilio": domicilio,
            "imagePathRUBROM": imagen
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
};