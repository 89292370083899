import axiosPost from '../axiosPost';

export const endUserLocationUpd = async (arg) => {
    const name = 'End User Location Update';
    let result;

    const { params } = arg

    const body = {
        "service": "EndUserOnboardDomicilioBOUpd",
        "params": params ? params : {}
    }

    try {
        result = await axiosPost('Y', body, arg)
    } catch (err) {
        console.log(err)
    }

    if (!result || result === null) {
        console.log(`Error getting ${name}`)
    }
    console.log(body)
    console.log(result.data)
    return result.data
}