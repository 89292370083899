import axiosPost from '../axiosPost';

export const localidadesGet = async (arg) => {
    const { params } = arg

    const body = {
        "service": "LocalidadGet",
        "params": params
    }

    try {
        const result = await axiosPost('Y', body, arg);
        return result.data.result
    } catch (err) {
        console.log(err)
    }
}