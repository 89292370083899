export const modalsInfoAseguradoras = {
    add: {
        title: "Agregar Aseguradora",
        insurer: { label: "Nombre de la aseguradora", placeholder: "Ingrese nombre" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        hooli: { label: "Hooli", placeholder: "Si" },
        button: "Agregar",
    },
    edit: {
        title: "Editar Aseguradora",
        insurer: { label: "Nombre de la aseguradora" },
        codigo: { label: "Codigo" },
        hooli: { label: "Hooli", placeholder: "Si" },
        button: "Guardar cambios",
    },
    delete: {
        title: "Eliminar Aseguradora",
        insurer: { label: "Nombre de la aseguradora" },
        codigo: { label: "Codigo" },
        hooli: { label: "Hooli" },
        button: "Eliminar",
    },
};

export const modalsInfoLocalidad = {
    add: {
        title: "Agregar Localidad",
        provincia: { label: "Provincia" },
        nombre: { label: "Nombre de la localidad", placeholder: "Ingrese nombre" },
        button: "Agregar",
    },
    edit: {
        title: "Editar Localidad",
        provincia: { label: "Provincia" },
        nombre: { label: "Nombre de la localidad" },
        button: "Guardar cambios",
    },
    delete: {
        title: "Eliminar Localidad",
        provincia: { label: "Provincia" },
        nombre: { label: "Nombre de la localidad" },
        button: "Eliminar",
    },
};

export const modalsInfoCondicionesAFIP = {
    add: {
        title: "Agregar Condición AFIP",
        nombre: { label: "Condicion AFIP", placeholder: "Ingrese condición AFIP" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        nombreAFIP: { label: "Nombre AFIP", placeholder: "Ingrese nombre AFIP" },
        button: "Agregar",
    },
    edit: {
        title: "Editar Condición AFIP",
        nombre: { label: "Nombre" },
        codigo: { label: "Codigo" },
        nombreAFIP: { label: "Nombre AFIP" },
        button: "Guardar cambios",
    },
    delete: {
        title: "Eliminar Condición AFIP",
        nombre: { label: "Nombre" },
        codigo: { label: "Codigo" },
        nombreAFIP: { label: "Nombre AFIP" },
        button: "Eliminar",
    },
};

export const modalsInfoTiposDeDomicilio = {
    add: {
        title: "Agregar Tipo de Domicilio",
        nombre: { label: "Tipo de domicilio", placeholder: "Ingrese tipo de domicilio" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        button: "Agregar",
    },
    edit: {
        title: "Editar Tipo de Domicilio",
        nombre: { label: "Tipo de domicilio" },
        codigo: { label: "Codigo" },
        button: "Guardar cambios",
    },
    delete: {
        title: "Eliminar Tipo de Domicilio",
        nombre: { label: "Tipo de domicilio" },
        codigo: { title: "Eliminar" },
        button: "Eliminar",
    },
};

export const modalsInfoTiposDeImagen = {
    add: {
        title: "Agregar Tipo de Imagen",
        nombre: { label: "Tipo de imagen", placeholder: "Ingrese tipo de imagen" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        bucket: { label: "Bucket", placeholder: "Ingrese Bucket" },
        folder: { label: "Folder", placeholder: "Ingrese folder" },
        button: "Agregar",
    },
    edit: {
        title: "Editar Tipo de Imagen",
        nombre: { label: "Tipo de imagen" },
        codigo: { label: "Codigo" },
        bucket: { label: "Bucket" },
        folder: { label: "Folder" },
        button: "Guardar cambios",
    },
    delete: {
        title: "Eliminar Tipo de Imagen",
        nombre: { label: "Tipo de imagen" },
        codigo: { label: "Bucket" },
        bucket: { label: "Folder" },
        folder: { title: "Eliminar" },
        button: "Eliminar",
    },
};

export const modalsInfoFormatoDeImagen = {
    add: {
        title: "Agregar Formato de Imagen",
        nombre: { label: "Formato de imagen", placeholder: "Ingrese formato de imagen" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        ancho: { label: "Ancho", placeholder: "Ingrese ancho en pixeles" },
        alto: { label: "Alto", placeholder: "Ingrese alto en pixeles" },
        button: "Agregar",
    },
    edit: {
        title: "Editar Formato de Imagen",
        nombre: { label: "Formato de imagen" },
        codigo: { label: "Codigo" },
        ancho: { label: "Ancho" },
        alto: { label: "Alto" },
        button: "Guardar cambios",
    },
    delete: {
        title: "Eliminar Formato de Imagen",
        nombre: { label: "Formato de imagen" },
        codigo: { label: "Codigo" },
        ancho: { label: "Ancho" },
        alto: { label: "Alto" },
        button: "Eliminar",
    },
};

export const modalsInfoFormatosPorTipoDeImagen = {
    add: {
        title: "Agregar Formato por tipo de imagen",
        nombre: { label: "Formato por tipo de imagen", placeholder: "Ingrese formato por tipo de imagen" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        ancho: { label: "Ancho", placeholder: "Ingrese ancho en pixeles" },
        alto: { label: "Alto", placeholder: "Ingrese alto en pixeles" },
        bucket: { label: "Bucket", placeholder: "Ingrese bucket" },
        folder: { label: "Folder", placeholder: "Ingrese folder" },
        button: "Agregar",
    },
    edit: {
        title: "Editar Formato por tipo de imagen",
        nombre: { label: "Formato por tipo de imagen" },
        codigo: { label: "Codigo" },
        ancho: { label: "Ancho" },
        alto: { label: "Alto" },
        bucket: { label: "Bucket" },
        folder: { label: "Folder" },
        button: "Guardar cambios",
    },
    delete: {
        title: "Eliminar Formato por tipo de imagen",
        nombre: { label: "Formato por tipo de imagen" },
        codigo: { label: "Codigo" },
        ancho: { label: "Ancho" },
        alto: { label: "Alto" },
        bucket: { label: "Bucket" },
        folder: { label: "Folder" },
        button: "Eliminar",
    },
};

export const modalsInfoMonedas = {
    add: {
        title: "Agregar Moneda",
        nombre: { label: "Moneda", placeholder: "Ingrese nombre" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        esBase: { label: "Es base" },
        button: "Agregar",
    },
    edit: {
        title: "Editar Moneda",
        nombre: { label: "Moneda" },
        codigo: { label: "Codigo" },
        esBase: { label: "Es base" },
        button: "Guardar cambios",
    },
    delete: {
        title: "Eliminar Moneda",
        nombre: { label: "Tipo de domicilio" },
        codigo: { label: "Codigo" },
        esBase: { label: "Es base" },
        button: "Eliminar",
    },
};

export const modalsInfoCotizaciones = {
    add: {
        title: "Agregar Cotización",
        cantidad: { label: "Cantidad que cotiza", placeholder: "Ingrese cantidad" },
        moneda: { label: "Moneda" },
        fecha: { label: "Fecha" },
        comprador: { label: "Comprador", placeholder: "Ingrese valor comprador" },
        vendedor: { label: "Vendedor", placeholder: "Ingrese valor vendedor" },
        button: "Agregar",
    },
    edit: {
        title: "Editar Cotización",
        cantidad: { label: "Cantidad que cotiza" },
        moneda: { label: "Moneda" },
        fecha: { label: "Fecha" },
        comprador: { label: "Comprador" },
        vendedor: { label: "Vendedor" },
        button: "Guardar cambios",
    },
    delete: {
        title: "Eliminar Cotización",
        cantidad: { label: "Cantidad que cotiza" },
        moneda: { label: "Moneda" },
        fecha: { label: "Fecha" },
        comprador: { label: "Comprador" },
        vendedor: { label: "Vendedor" },
        button: "Eliminar",
    },
};

export const modalsInfoFeriados = {
    add: {
        title: "Agregar Feriado",
        nombre: { label: "Feriado", placeholder: "Ingrese feriado" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Feriado",
        nombre: { label: "Feriado" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Feriado",
        nombre: { label: "Feriado" },
        button: "Eliminar"
    },
};

export const modalsInfoSerieDeFeriados = {
    add: {
        title: "Agregar serie de feriados",
        fecha: { label: "Feriado" },
        comercial: { label: "Comercial" },
        bancario: { label: "Bancario" },
        button: "Agregar"
    },
    edit: {
        title: "Editar serie de feriados",
        fecha: { label: "Feriado" },
        comercial: { label: "Comercial" },
        bancario: { label: "Bancario" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar serie de feriados",
        fecha: { label: "Feriado" },
        comercial: { label: "Comercial" },
        bancario: { label: "Bancario" },
        button: "Eliminar"
    },
};

export const modalsInfoRegistros = {
    add: {
        title: "Agregar Registro",
        nombre: { label: "Registro", placeholder: "Ingrese registro" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        descripcion: { label: "Descripcion", placeholder: "Ingrese descripcion" },
        bo: { label: "Requiere BO" },
        tipoDeRegistro: { label: "Tipo de registro" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Registro",
        nombre: { label: "Registro" },
        codigo: { label: "Codigo" },
        descripcion: { label: "Descripcion" },
        bo: { label: "Requiere BO" },
        tipoDeRegistro: { label: "Tipo de registro" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Registro",
        nombre: { label: "Registro" },
        codigo: { label: "Codigo" },
        descripcion: { label: "Descripcion" },
        bo: { label: "Requiere BO" },
        tipoDeRegistro: { label: "Tipo de registro" },
        button: "Eliminar"
    },
};

export const modalsInfoTiposDeRegistro = {
    add: {
        title: "Agregar Tipo de Registro",
        nombre: { label: "Tipo de registro", placeholder: "Ingrese tipo de registro" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Tipo de Registro",
        nombre: { label: "Tipo de registro" },
        codigo: { label: "Codigo" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Tipo de Registro",
        nombre: { label: "Tipo de registro" },
        codigo: { label: "Codigo" },
        button: "Eliminar"
    },
};

export const modalsInfoEstadosDeRegistro = {
    add: {
        title: "Agregar Estado de Registro",
        nombre: { label: "Estado de registro", placeholder: "Ingrese estado de registro" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Estado de Registro",
        nombre: { label: "Estado de registro" },
        codigo: { label: "Codigo" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Estado de Registro",
        nombre: { label: "Estado de registro" },
        codigo: { label: "Codigo" },
        button: "Eliminar"
    },
};

export const modalsInfoEventosDeSesion = {
    add: {
        title: "Agregar Evento de Sesion",
        nombre: { label: "Evento de sesion", placeholder: "Ingrese evento de sesion" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Evento de Sesion",
        nombre: { label: "Evento de sesion" },
        codigo: { label: "Codigo" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Evento de Sesion",
        nombre: { label: "Evento de sesion" },
        codigo: { label: "Codigo" },
        button: "Eliminar"
    },
};

export const modalsInfoTiposDeDevice = {
    add: {
        title: "Agregar Tipo de Device",
        nombre: { label: "Tipo de device", placeholder: "Ingrese tipo de device" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Tipo de Device",
        nombre: { label: "Tipo de device" },
        codigo: { label: "Codigo" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Tipo de Device",
        nombre: { label: "Tipo de device" },
        codigo: { label: "Codigo" },
        button: "Eliminar"
    },
};

export const modalsInfoZonaDeNoEnroll = {
    add: {
        title: "Agregar Zona de no enroll",
        nombre: { label: "Zona de no enroll", placeholder: "Ingrese zona" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Zona de no enroll",
        nombre: { label: "Zona de no enroll" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Zona de no enroll",
        nombre: { label: "Zona de no enroll" },
        button: "Eliminar"
    },
};

export const modalsInfoMerchants = {
    add: {
        title: "Agregar Merchant",
        nombre: { label: "Nombre", placeholder: "Ingrese nombre" },
        cuit: { label: "CUIT", placeholder: "Ingrese CUIT" },
        plan: { label: "Plan", placeholder: "Ingrese plan" },
        rubro: { label: "Rubro", placeholder: "Ingrese rubro" },
        acreditacion: { label: "Acreditacion", placeholder: "Ingrese acreditacion" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Merchant",
        nombre: { label: "Nombre" },
        cuit: { label: "CUIT" },
        plan: { label: "Plan" },
        rubro: { label: "Rubro" },
        acreditacion: { label: "Acreditacion" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Merchant",
        nombre: { label: "Nombre" },
        cuit: { label: "CUIT" },
        plan: { label: "Plan" },
        rubro: { label: "Rubro" },
        acreditacion: { label: "Acreditacion" },
        button: "Eliminar"
    },
};

export const modalsInfoRubrosDeMerchant = {
    add: {
        title: "Agregar Rubro de Merchant",
        nombre: { label: "Rubro de merchant", placeholder: "Ingrese rubro de merchant" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        agencia: { label: "Es Agencia" },
        domicilio: { label: "A Domicilio" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Rubro de Merchant",
        nombre: { label: "Rubro de merchant" },
        codigo: { label: "Codigo" },
        agencia: { label: "Es Agencia" },
        domicilio: { label: "A Domicilio" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Rubro de Merchant",
        nombre: { label: "Rubro de merchant" },
        codigo: { label: "Codigo" },
        agencia: { label: "Es Agencia" },
        domicilio: { label: "A Domicilio" },
        button: "Eliminar"
    },
};

export const modalsInfoCargos = {
    add: {
        title: "Agregar Cargo",
        nombre: { label: "Cargo", placeholder: "Ingrese cargo" },
        codigo: { label: "Codigo", placeholder: "Ingrese codigo" },
        button: "Agregar"
    },
    edit: {
        title: "Editar Cargo",
        nombre: { label: "Cargo" },
        codigo: { label: "Codigo" },
        button: "Guardar cambios"
    },
    delete: {
        title: "Eliminar Cargo",
        nombre: { label: "Cargo" },
        codigo: { label: "Codigo" },
        button: "Eliminar"
    },
};

export const modalsInfoPreonboardingManual = {
    title: "Añadir usuario",
    nombre: { label: "Nombre y apellido" },
    nacimiento: { label: "Fecha de nacimiento" },
    dni: { label: "DNI" },
    sexo: { label: "Sexo" },
    tramite: { label: "Numero de tramite" },
    vencimientoDNI: { label: "Vencimiento del DNI" },
    cuit: { label: "CUIT" },
    match: { label: "Match renaper" },
    localidad: { label: "Localidad" },
    provincia: { label: "Provincia" },
    calle: { label: "Calle" },
    numero: { label: "Numero" },
    piso: { label: "Piso" },
    CP: { label: "Codigo postal" },
    button: "Añadir usuario"
}