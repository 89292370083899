import React, { useState, useEffect } from "react";
// styles
import "../pages.css";
// components
import Filtros from './Filtros';
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
// consts
import { cotizacionesDeMonedas as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoCotizaciones as infoModal } from "../../consts/Modals-info";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { cotizacionesDeMonedasGet } from '../../services/hooli-services/CotizacionesDeMonedas/CotizacionesDeMonedasGet';
import { monedasGet } from "../../services/hooli-services/Monedas/MonedasGet";
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, rowSelected, formData } from '../../recoilState/GlobalState';

export default function CotizacionesMonedas() {
    const [rates, setRates] = useState([]);
    const [rows, setRows] = useState([{ id: 1 }]);
    const [action, setAction] = useState({});
    const [monedas, setMonedas] = useState([]);
    const [filtros, setFiltros] = useState({moneda: 1, desde: new Date().toISOString().substring(0, 10), hasta: new Date().toISOString().substring(0, 10)})
    const [cargando, setCargando] = useState(true);

    const setRow = useSetRecoilState(rowSelected)
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData)

    async function retrieveRates() {
        const params = { "monedaId": filtros.moneda }

        const payload = { ...credentials, params };
        setRows([]);
        setCargando(true);
        const data = await cotizacionesDeMonedasGet(payload)
        setRates(data);
        setCargando(false);
        setRow({ status: false })
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) {
            retrieveData(credentials, undefined, monedasGet, setMonedas)
        }
    }, [credentials]);

    useEffect(() => {
        if (filtros && Object.keys(credentials).length > 0) retrieveRates()
    }, [filtros, credentials])

    useEffect(() => {
        rates.map((item) => {
            const {
                monedaSerieId,
                monedaSerieVersion,
                monedaSerieFecha,
                monedaSerieQMoneda,
                monedaSerieValorComprador,
                monedaSerieValorVendedor,
            } = item

            const cotizacion = {
                id: monedaSerieId,
                version: monedaSerieVersion,
                [titles[0]]: monedaSerieFecha,
                [titles[1]]: monedaSerieQMoneda,
                [titles[2]]: monedaSerieValorComprador,
                [titles[3]]: monedaSerieValorVendedor
            }

            setRows(rows => [...rows, cotizacion])
            return item
        });
    }, [rates])

    useEffect(() => setRow({ status: false }), [])

    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action]);
        setAction({ action, procede });
    };

    return (
        <main className="container">
            <TransitionModal
                titles={titles}
                procede={action.procede}
                monedas={monedas}
                monedaSeleccionada={filtros.moneda}
                updateList={retrieveRates}
            />
            <Filtros
                monedas={monedas}
                filtros={filtros}
                setFiltros={setFiltros}
                handleClick={handleClick}
                cargando={cargando}
                updateList={retrieveRates}
            />
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};