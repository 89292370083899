import React from 'react';
// style
import classes from './endUserModal.module.css'
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import editar from "../../assets/icons/editar.svg";
import modalEditar from "../../assets/icons/modal-editar.svg";
import CheckIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
// Recoil
import { useSetRecoilState } from 'recoil'
import { formIsOpen } from '../../recoilState/GlobalState';


export default function EndUserModal(props) {
    const setIsOpen = useSetRecoilState(formIsOpen);
    const { userStatus, cargando, setProcede } = props.props;

    return (
        <div className={classes.container}>
            <h2 className={classes.title}>Estado de la Documentacion</h2>
            <Divider className={classes.divider} />
            {cargando ?
                <CircularIndeterminate /> :
                userStatus
                    .filter(status => status.estadoDeRegistroCod !== 'F')
                    .map(status => {
                        return (
                            <div key={`${status.registroCod} ${status.registroId}`} className={classes.result}>
                                <div className={classes.status}>
                                    <div className={classes.icon}>
                                        {status.estadoDeRegistroCod === 'A' && <CheckIcon fontSize={"large"} className={classes.checkIcon} />}
                                        {status.estadoDeRegistroCod === 'E' && <img src={modalEditar} className={classes.updateIcon} />}
                                        {status.estadoDeRegistroCod === 'R' && <CancelIcon fontSize={"large"} className={classes.cancelIcon} />}
                                    </div>
                                    <p className={classes.registro}>{status.registroNombre}</p>
                                    {status.registroId === 2 ||
                                        status.registroId === 3 ||
                                        status.registroId === 4 ||
                                        status.registroId === 5 ||
                                        status.registroId === 7 ?
                                        <img className={classes.editIcon} src={editar} onClick={() => setProcede(status.registroId)} />
                                        : null
                                    }
                                </div>
                                <Divider className={classes.divider} />
                            </div>
                        )
                    })}
            <div className={classes.closeButtonContainer}>
                <Button className={classes.button} onClick={() => setIsOpen(false)}>
                    <span>Cerrar</span>
                </Button>
            </div>
        </div>
    )
};