import React, { useState, useRef, useEffect } from 'react';
import "cropperjs/dist/cropper.css";
import classes from './PreonboardingManual.module.css';
// Components
import Cropper from 'react-cropper';
import FormButton from '../../components/utils/button/FormButton';
// Recoil
import { useSetRecoilState } from 'recoil';
import { formIsOpen } from '../../recoilState/GlobalState';

export default function ModalRecorteImagen({ props }) {
    const [cutImage, setCutImage] = useState();
    const cropperRef = useRef(null);
    const imageRef = useRef(null);

    const setIsOpen = useSetRecoilState(formIsOpen);

    const { frenteDNI, frenteRef, setRecorte } = props;

    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        setCutImage(cropper.getCroppedCanvas({ height: 250, imageSmoothingEnabled: true, imageSmoothingQuality: 'low' }).toDataURL());
    };

    const handleAction = () => {
        setRecorte(cutImage);
        setIsOpen(false);
    };

    const newFormData = {
        button: 'Recortar'
    };

    useEffect(() => {
        cropperRef.current.style.transform = frenteRef.current.style.transform;
        if(imageRef && imageRef.current) imageRef.current.style.transform = frenteRef.current.style.transform;
    }, [frenteRef.current.style, cutImage]);
    

    return (
        <div className={classes.modalRecorte}>
            <h2 className={classes.title}>Recortar imagen</h2>
            <div className={classes.cropperContainer}>
                <Cropper
                    src={`data:image/png;base64,${frenteDNI}`}
                    style={{ height: 400, width: "100%" }}
                    guides={false}
                    crop={onCrop}
                    ref={cropperRef}
                    className={classes.cropper}
                />
                {cutImage &&
                    <img
                        src={cutImage}
                        className={classes.cropper}
                        ref={imageRef}
                    />
                }
            </div>
            <FormButton handleAction={handleAction} newFormData={newFormData} />
        </div>
    );
}
