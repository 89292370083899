import React, { useState, useEffect } from "react";
// components
import Fitlros from './Filtros';
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
// consts
import { seriesDeFeriados as titles } from "../../consts/titulos-de-tablas";
import { modalsInfoSerieDeFeriados as infoModal } from "../../consts/Modals-info";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { retrieveData } from '../../services/Local-services';
import { feriadosGet } from '../../services/hooli-services/Feriados/FeriadosGet';
import { serieDeFeriadosGet } from '../../services/hooli-services/SerieDeFeriados/SerieDeFeriadosGet';
// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, rowSelected, formData } from '../../recoilState/GlobalState';

export default function SerieDeFeriados() {
    const [serieDeFeriados, setSerieDeFeriados] = useState([]);
    const [rows, setRows] = useState([]);
    const [feriados, setFeriados] = useState([]);
    const [feriadoSeleccionado, setFeriadoSeleccionado] = useState(1);
    const [action, setAction] = useState({});
    const [cargando, setCargando] = useState(true);

    const setRow = useSetRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData);

    async function retrieveHolidays() {
        const params = { "feriadoId": feriadoSeleccionado }

        const payload = { ...credentials, params };
        setRows([]);
        setCargando(true);
        const data = await serieDeFeriadosGet(payload);
        setCargando(false);
        setSerieDeFeriados(data)
        setRow({ status: false })
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveData(credentials, undefined, feriadosGet, setFeriados)
    }, [credentials])

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveHolidays()
    }, [credentials, feriadoSeleccionado]);

    useEffect(() => {
        serieDeFeriados.map((item) => {
            const {
                feriadoSerieId,
                feriadoSerieVersion,
                feriadoSerieFecha,
                feriadoSerieEsComercial,
                feriadoSerieEsBancario
            } = item

            const serie = {
                id: feriadoSerieId,
                version: feriadoSerieVersion,
                comercial: feriadoSerieEsComercial,
                bancario: feriadoSerieEsBancario,
                [titles[0]]: feriadoSerieFecha,
                [titles[1]]: feriadoSerieEsComercial === 'Y' ? 'Si' : 'No',
                [titles[2]]: feriadoSerieEsBancario === 'Y' ? 'Si' : 'No',
            }

            setRows(rows => [...rows, serie])
            return item
        });
    }, [serieDeFeriados]);

    useEffect(() => setRow({ status: false }), [])

    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action]);
        setAction({ action, procede });
    };

    return (
        <main className="container">
            <TransitionModal
                titles={titles}
                procede={action.procede}
                feriadoSeleccionado={feriadoSeleccionado}
                updateList={retrieveHolidays}
            />
            <Fitlros
                feriados={feriados}
                feriadoSeleccionado={feriadoSeleccionado}
                setFeriadoSeleccionado={setFeriadoSeleccionado}
                handleClick={handleClick}
                cargando={cargando}
                updateList={retrieveHolidays}
            />
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};