import React, { useState, useEffect } from "react";
// styles
import "../pages.css";
// components
import Filtros from './Filtros';
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
// consts
import { endUser as titles } from "../../consts/titulos-de-tablas";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { endUserGet } from '../../services/hooli-services/EndUser/EndUserGet';
import { registrosGet } from "../../services/hooli-services/Registros/RegistrosGet";
import { usuarioStatusGet } from "../../services/hooli-services/EndUser/UsuarioStatusGet"
// recoil
import { useRecoilValue, useRecoilState } from 'recoil';
import { userCredentials, rowSelected } from '../../recoilState/GlobalState';

export default function EndUser() {
    const [users, setUsers] = useState([]);
    const [registros, setRegistros] = useState([]);
    const [userStatus, setUserStatus] = useState([]);
    const [rows, setRows] = useState([]);
    const [filtros, setFiltros] = useState({ registro: 'todos', documento: '', apellido: '' });
    const [cargando, setCargando] = useState(true);
    const [modalCargando, setModalCargando] = useState(true);
    const [procede, setProcede] = useState();

    const [row, setRow] = useRecoilState(rowSelected)
    const credentials = useRecoilValue(userCredentials);

    async function retrieveEndUsers() {
        const params = {
            "usuarioApellidos": filtros.apellido,
            "usuarioNroDeDocumento": filtros.documento.replace(/\./g, ''),
            "registroId": filtros.registro === 'todos' ? null : filtros.registro,
        }

        const payload = { ...credentials, params };
        setCargando(true);

        const data = await endUserGet(payload)
        setRows([]);
        setCargando(false)
        setUsers(data)
        setRow({ status: false });
    };

    async function retrieveRegisters() {
        const params = { "tipoRegistroCod": "E" }

        const payload = { ...credentials, params };

        const data = await registrosGet(payload);
        setRegistros(data);
    };

    async function retrieveUserStatus() {
        const params = {
            "usuarioUID": row.row ? row.row.id : '',
            "rolCod": "E"
        }

        const payload = { ...credentials, params };
        setModalCargando(true);
        const data = await usuarioStatusGet(payload)
        setModalCargando(false);
        setUserStatus(data)
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveRegisters()
    }, [credentials]);

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveEndUsers()
    }, [credentials, filtros]);

    useEffect(() => {
        if (users.length > 0) {
            users
                .sort((a, b) => a.usuarioFechaAlta && b.usuarioFechaAlta ? parseInt(b.usuarioFechaAlta.substring(0, 10).replace(/-/g, '')) - parseInt(a.usuarioFechaAlta.substring(0, 10).replace(/-/g, '')) : a.usuarioApellidos - b.usuarioApellidos)
                .map((item) => {
                    const {
                        usuarioId,
                        usuarioApellidos,
                        usuarioNombres,
                        tipoDeDocumentoCod,
                        usuarioNroDeDocumento,
                        usuarioSexo,
                        usuarioStatusRegistro,
                        usuarioFechaAlta,
                        cuentaOK,
                        cuentaBO,
                        cuentaRE,
                        usuarioCVU,
                        usuarioCVUAlias,
                    } = item;

                    const users = {
                        id: usuarioId,
                        aprobados: cuentaOK,
                        enEspera: cuentaBO,
                        rechazados: cuentaRE,
                        [titles[0]]: usuarioApellidos,
                        [titles[1]]: usuarioNombres,
                        [titles[2]]: tipoDeDocumentoCod,
                        [titles[3]]: usuarioNroDeDocumento.toLocaleString('es-ES'),
                        [titles[4]]: usuarioSexo,
                        [titles[5]]: usuarioFechaAlta,
                        [titles[6]]: usuarioCVU,
                        [titles[7]]: usuarioCVUAlias,
                        [titles[8]]: usuarioStatusRegistro,
                    };

                    setRows(rows => [...rows, users])
                    return item
                })
        }
    }, [users]);

    useEffect(() => {
        if (Object.keys(credentials).length > 0 && row.row) retrieveUserStatus()
    }, [credentials, row])

    useEffect(() => setRow({ status: false }), []);

    return (
        <main className="container">
            <TransitionModal
                procede={procede}
                setProcede={setProcede}
                userStatus={userStatus}
                cargando={modalCargando}
                updateList={retrieveEndUsers}
                updateModalList={retrieveUserStatus}
            />
            <Filtros
                filtros={filtros}
                setFiltros={setFiltros}
                cargando={cargando}
                registros={registros}
                updateList={retrieveEndUsers}
            />
            <div className="row-grid" >
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};