import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    match: yup.number().min(0.4, 'No match')
})

export const formikValidation = (userInfo) => formik({
    initialValues: {
        nombre: userInfo.usuarioNombres + " " + userInfo.usuarioApellidos,
        nacimiento: userInfo.usuarioFechaDeNacimiento,
        dni: userInfo.usuarioNroDeDocumento,
        sexo: userInfo.usuarioSexo === 'M' ? 'Masculino' : 'Femenino',
        tramite: userInfo.usuarioDNITramite,
        vencimientoDNI: userInfo.usuarioVtoDeDocumento,
        cuit: userInfo.usuarioCUIT,
        match: userInfo.usuarioDNIMatchRENAPER,
        localidad: userInfo.localidadId,
        calle: userInfo.domicilioCalle,
        numero: userInfo.domicilioCasa,
        piso: userInfo.domicilioPiso,
        CP: userInfo.domicilioCPA
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: () => {}
});