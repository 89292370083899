import React, { useState, useEffect } from 'react';
import Filtros from './Filtros';
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
import TransitionModal from "../../components/utils/modals/Modals";
// Const
import { modalsInfoRegistros as infoModal } from "../../consts/Modals-info";
import { registros as titles } from '../../consts/titulos-de-tablas';
//Services 
import { retrieveData } from '../../services/Local-services';
import { tiposDeRegistroGet } from '../../services/hooli-services/TiposDeRegistro/TiposDeRegistroGet';
import { registrosGet } from '../../services/hooli-services/Registros/RegistrosGet';
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
// recoil 
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { formData, userCredentials } from '../../recoilState/GlobalState';
import { rowSelected } from '../../recoilState/GlobalState';

export default function Registros() {
    const [action, setAction] = useState({});
    const [registros, setRegistros] = useState([]);
    const [tiposDeRegistro, setTiposDeRegistro] = useState([]);
    const [tipoDeRegistroSeleccionado, setTipoDeRegistroSeleccionado] = useState('todos');
    const [rows, setRows] = useState([]);
    const [cargando, setCargando] = useState(true);

    const setRow = useSetRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setFormInfo = useSetRecoilState(formData);

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveData(credentials, undefined, tiposDeRegistroGet, setTiposDeRegistro)
    }, [credentials]);

    async function retrieveRegisters() {
        const params = tipoDeRegistroSeleccionado === "todos" ? {} : { "tipoRegistroId": tipoDeRegistroSeleccionado }

        const payload = { ...credentials, params };
        setCargando(true);
        setRows([]);

        const data = await registrosGet(payload)
        setRegistros(data)
        setCargando(false)
        setRow({ status: false })
    };

    useEffect(() => {
        if (tipoDeRegistroSeleccionado && Object.keys(credentials).length > 0) retrieveRegisters()
    }, [tipoDeRegistroSeleccionado, credentials]);

    useEffect(() => {
        registros.map((item) => {
            const {
                registroId,
                registroVersion,
                tipoRegistroId,
                registroNombre,
                registroCod,
                registroDescripcion,
                registroRequiereBO
            } = item;

            const registro = {
                id: registroId,
                version: registroVersion,
                tipoDeRegistro: tipoRegistroId,
                requiereBO: registroRequiereBO,
                [titles[0]]: registroNombre,
                [titles[1]]: registroCod,
                [titles[2]]: registroDescripcion,
                [titles[3]]: registroRequiereBO === 'Y' ? 'Si' : 'No',
            };

            setRows(rows => [...rows, registro])
            return item
        });
    }, [registros]);

    const handleClick = ({ action, procede }) => {
        setFormInfo(infoModal[action]);
        setAction({ action, procede });
    };

    return (
        <main className="container">
            <TransitionModal
                titles={titles}
                procede={action.procede}
                tiposDeRegistro={tiposDeRegistro}
                updateList={retrieveRegisters}
            />
            <Filtros
                tiposDeRegistro={tiposDeRegistro}
                tipoDeRegistroSeleccionado={tipoDeRegistroSeleccionado}
                setTipoDeRegistroSeleccionado={setTipoDeRegistroSeleccionado}
                handleClick={handleClick}
                cargando={cargando}
                updateList={retrieveRegisters}
            />
            <div className="row-grid" >
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};