import React from 'react'

function FactoresBiometricos() {
    return (
        <div className="datos-personales">
            <div className="datos-personales-column">
                <div className="card-gris">
                    <p className="card-title">Face</p>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Failed Attempts</p>
                        <p className="dato-personal">15</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Unlock count</p>
                        <p className="dato-personal">10</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Ingreso a la App</p>
                        <p className="dato-personal">Primario</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Transferencia</p>
                        <p className="dato-personal">Secundario</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Pago comercios</p>
                        <p className="dato-personal">Primario</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Pago servicios</p>
                        <p className="dato-personal">Primario</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Login by Hooli</p>
                        <p className="dato-personal">Secundario</p>
                    </div>
                </div>
                <div className="rectangulo-verde">Activo</div>
            </div>
            <div className="datos-personales-column">
                <div className="card-gris">
                    <p className="card-title">Palm</p>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Failed Attempts</p>
                        <p className="dato-personal">15</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Unlock count</p>
                        <p className="dato-personal">10</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Ingreso a la App</p>
                        <p className="dato-personal">Primario</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Transferencia</p>
                        <p className="dato-personal">-</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Pago comercios</p>
                        <p className="dato-personal">Secundario</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Pago servicios</p>
                        <p className="dato-personal">Primario</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Login by Hooli</p>
                        <p className="dato-personal">Secundario</p>
                    </div>
                </div>
                <div className="rectangulo-rojo">Bloqueado</div>
            </div>
            <div className="datos-personales-column">
                <div className="card-gris">
                    <p className="card-title">Voice</p>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Failed Attempts</p>
                        <p className="dato-personal">15</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Unlock count</p>
                        <p className="dato-personal">10</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Ingreso a la App</p>
                        <p className="dato-personal">-</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Transferencia</p>
                        <p className="dato-personal">Primario</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Pago comercios</p>
                        <p className="dato-personal">-</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Pago servicios</p>
                        <p className="dato-personal">-</p>
                    </div>
                    <div className="container-dato-biometrico">
                        <p className="dato-personal">Login by Hooli</p>
                        <p className="dato-personal">-</p>
                    </div>
                </div>
                <div className="rectangulo-gris-oscuro">No enrolado</div>
            </div>
            <div className="datos-personales-column">
                <div className="card-gris">
                    <p className="card-title">Proximamente</p>
                </div>
                <div className="rectangulo-gris-oscuro"></div>
            </div>
        </div>
    )
}

export default FactoresBiometricos