import { Button } from '@material-ui/core';
import React from 'react';
import { SubmitButtonStyles } from './SubmitButton.styles';

export default function SubmitButton({ texto }) {
    const classes = SubmitButtonStyles();
    return (
        <Button type="submit" className={classes.root}>
            {texto}
        </ Button>
    )
};