import axiosPost from '../axiosPost';

export const cotizacionesDeMonedasIns = async (arg) => {
    const { cantidad, moneda, fecha, comprador, vendedor } = arg;

    const body = {
        "service": "MonedaSerieIns",
        "params": {
            "monedaId": moneda,
            "monedaSerieFecha": fecha,
            "monedaSerieQMoneda": cantidad,
            "monedaSerieValorComprador": comprador,
            "monedaSerieValorVendedor": vendedor
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}