import axiosPost from '../axiosPost';

export const condicionesAFIPDel = async (arg) => {
    const { id } = arg;

    const body = {
        "service": "CondicionAFIPDel",
        "params": {
            "condicionAFIPId": id
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
};