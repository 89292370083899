import { makeStyles } from "@material-ui/core/styles";

export const XGridStyles = makeStyles(() => ({
    root: {
        cursor: 'pointer',
        position: 'relative',
        '& *': {
            outline: 'none!important',
            overflow: 'hidden!important',
        },
        '& .MuiDataGrid-footer': {
            justifyContent: 'flex-start!important'
        },
        '& .MuiDataGrid-row': {
            '&:hover': {
                backgroundColor: '#F2F2F2'
            }
        },
        '& .MuiDataGrid-row.Mui-even': {
            backgroundColor: '#FAFAFA',
        },
        '& .MuiDataGrid-row.Mui-odd': {
            backgroundColor: '#FFF',
        },
        '& .MuiDataGrid-row.Mui-even.Mui-selected': {
            backgroundColor: '#A7A7A7',
            '&:hover': {
                backgroundColor: '#A7A7A7'
            }
        },
        '& .MuiDataGrid-row.Mui-odd.Mui-selected': {
            backgroundColor: '#A7A7A7',
            '&:hover': {
                backgroundColor: '#A7A7A7'
            }
        }
    },
    paginador: {
        position: 'absolute',
        bottom: '10px',
        left: '240px',
    }
}));