import React, { useState, useEffect } from "react";
// utils
import FormButton from "../../button/FormButton";
import CommonForm from '../CommonForm';
import { FormikTextInput } from "../../inputs/FormikInputs";
// functions
import { postCargo, deleteCargo, updateCargo } from './CargosFunctions';
import { handleResponse } from "../../../../services/Local-services";
// validaciones
import { formikValidation } from './CargosFormValidaciones';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState';

export default function CargosForm(props) {
    const [newCargo, setNewCargo] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, procede } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        if (procede === 'Deleteone') {
            const response = await deleteCargo(credentials, selected.row, setInRequest);
            return handleResponse(response, 'Cargo eliminado exitosamente', functions);
        }
        if (procede === 'Addone') {
            const response = await postCargo(credentials, newCargo, setInRequest);
            return handleResponse(response, 'Cargo añadido exitosamente', functions);
        }
        if (procede === 'Editone') {
            const response = await updateCargo(credentials, newCargo, selected.row, setInRequest);
            return handleResponse(response, 'Cargo modificado exitosamente', functions);
        }
    };

    useEffect(() => {
        if (procede !== 'Deleteone') handleAction();
    }, [newCargo])

    const formik = formikValidation(setNewCargo, selected.row, titles);
    const disabled = inRequest || procede === 'Deleteone';

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikTextInput
                data={data}
                name="nombre"
                formik={formik}
                disabled={disabled}
            />
            <FormikTextInput
                data={data}
                name="codigo"
                formik={formik}
                disabled={disabled}
            />
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};