import React, { useState, useEffect } from 'react'
import FactoresBiometricos from './FactoresBiometricos';
import XGrid from '../../../components/utils/grid/Xgrid';

const titles = ['Fecha de alta', 'Dispositivo', 'Tipo de autenticador', 'App ID', 'Devide ID', 'Estado']
const rows = [
    {
        id: 1,
        'Fecha de alta': '22/09/2020 15:54',
        'Dispositivo': 'Iphone SE (OS 14.1)',
        'Tipo de autenticador': 'Daon AdoS Face Authentic…',
        'App ID': '00138021396F19940922',
        'Devide ID': 'Iphone de Guada',
        'Estado': 'Activo'
    },
    {
        id: 2,
        'Fecha de alta': '22/09/2020 15:54',
        'Dispositivo': 'Iphone SE (OS 14.1)',
        'Tipo de autenticador': 'Daon AdoS Face Authentic…',
        'App ID': '00138021396F19940922',
        'Devide ID': 'Iphone de Guada',
        'Estado': 'Activo'
    },
    {
        id: 3,
        'Fecha de alta': '22/09/2020 15:54',
        'Dispositivo': 'Iphone SE (OS 14.1)',
        'Tipo de autenticador': 'Daon AdoS Face Authentic…',
        'App ID': '00138021396F19940922',
        'Devide ID': 'Iphone de Guada',
        'Estado': 'Activo'
    }
];

function DatosBiometricos() {
    const [selected, setSelected] = useState(1);
    const gridContainer = document.getElementById('grid-container')
    const gridWidth = gridContainer ? gridContainer.offsetWidth : 0;
    
    return (
        <>
            <div className="grid-datos-biometricos" >
                <XGrid rows={rows} titles={titles} width={gridWidth} indice={0}/>
            </div>
            <div className="container-pantallas-usuario">
                <div className={selected === 1 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(1)}>Factores biometricos</div>
                <div className={selected === 2 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(2)}>One-Time Password Authentication Methods (OTP)</div>
                <div className={selected === 3 ? "select-pantalla-usuario violeta" : "select-pantalla-usuario"} onClick={() => setSelected(3)}>FIDO Device Authentication Methods</div>
            </div>
            {selected === 1 && <FactoresBiometricos />}
        </>
    )
}

export default DatosBiometricos
