import React from 'react';
// Components
import Buttons from '../../components/utils/buttonsContainer/Buttons';
import { FormikSelectInput } from '../../components/utils/inputs/FormikInputs';
// Styles
import { FiltrosStyles } from '../Filtros.styles';
// recoil
import { useRecoilValue } from 'recoil';
import { rowSelected } from '../../recoilState/GlobalState';
import { useFormik } from 'formik';

export default function Filtros(props) {
    const { status } = useRecoilValue(rowSelected);

    const { filtros, setFiltros, formatos, tipos, handleClick, cargando, updateList } = props;

    const formik = useFormik({
        initialValues: filtros,
        // validationSchema,
        onSubmit: (values) => setFiltros(values)
    });

    const classes = FiltrosStyles();
    const data = { tipoDeImagen: { label: 'Tipos de imagen' }, formatoDeImagen: { label: 'Formatos de imagen' } };

    return (
        <form className={classes.root} onSubmit={formik.handleSubmit}>
            <div className={classes.filtros}>
                <FormikSelectInput
                    data={data}
                    name="tipoDeImagen"
                    formik={formik}
                    disabled={cargando}
                    options={tipos}
                    optionKey="tipoDeImagenId"
                    optionValue="tipoDeImagenId"
                    optionName="tipoDeImagenNombre"
                    className={classes.input}
                />
                <FormikSelectInput
                    data={data}
                    name="formatoDeImagen"
                    formik={formik}
                    disabled={cargando}
                    options={formatos}
                    optionKey="formatosXTipoDeImagenId"
                    optionValue="formatosXTipoDeImagenId"
                    optionName="formatosXTipoDeImagenNombre"
                    className={classes.input}
                />
            </div>
            <Buttons texto="formato" handleClick={handleClick} updateList={updateList} />
        </form>
    )
};