import axiosPost from '../axiosPost';

export const localidadesIns = async (arg) => {
    const { provincia, nombre } = arg;

    const body = {
        "service": "LocalidadIns",
        "params": {
            "provinciaId": provincia,
            "localidadNombre": nombre,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        console.log(result.data)
        return result.data
    } catch (err) {
        console.log(err)
    }
};