import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    nombre: yup
        .string()
        .max(45, 'Debe tener un maximo de 45 letras')
        .required("Ingrese un nombre")
})

export const formikValidation = (setNewFeriado, row, titles) => formik({
    initialValues: {
        nombre: row && row[titles[0]] ? row[titles[0]] : ''
    },
    validationSchema,
    onSubmit: (values) => setNewFeriado(values)
});