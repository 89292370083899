import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    provincia: yup.string().required('Seleccione una opcion'),
    nombre: yup.string().required('Ingrese nombre')
});

export const formikValidation = (setNewLocalidad, row, titles) => formik({
    initialValues: {
        provincia: row && row.provinciaId ? row.provinciaId : 1,
        nombre: row && row[titles[0]] ? row[titles[0]] : "",
    },
    validationSchema,
    onSubmit: (values) => setNewLocalidad(values)
});