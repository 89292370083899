import axiosPost from '../axiosPost';

export const formatosXTipoDeImagenIns = async (arg) => {
    const { nombre, codigo, ancho, alto, bucket, folder } = arg;

    const body = {
        "service": "FormatoXTipoDeImagenIns",
        "params": {
            "formatoXTipoDeImagenNombre": nombre,
            "formatoXTipoDeImagenCod": codigo,
            "formatoXTipoDeImagenAncho": ancho,
            "formatoXTipoDeImagenAlto": alto,
            "formatoXTipoDeImagenBucket": bucket,
            "formatoXTipoDeImagenFolder": folder,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}