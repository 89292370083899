import * as yup from "yup";
import { useFormik as formik } from "formik";

const validationSchema = yup.object({
    fecha: yup.date().required("Seleccione una fecha"),
    comercial: yup.string().required('Seleccione una opcion'),
    bancario: yup.string().required('Seleccione una opcion')
})

export const formikValidation = (setNewSerie, row, titles) => formik({
    initialValues: {
        fecha: row && row[titles[0]] ? row[titles[0]] : new Date().toISOString().substring(0, 10),
        comercial: row && row.comercial ? row.comercial : 'Y',
        bancario: row && row.bancario ? row.bancario : 'Y',
    },
    validationSchema,
    onSubmit: (values) => setNewSerie(values)
});