import axiosPost from '../axiosPost';

export const merchantsDel = async (arg) => {
    const { id } = arg;

    const body = {
        "service": "MerchantDel",
        "params": {
            "merchantId": id
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}