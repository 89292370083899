import React from 'react'
import { Divider } from '@material-ui/core';
import { CommonFormStyles } from './CommonForm.styles'

export default function CommonForm({ onSubmit, title, procede, children }) {
    const classes = CommonFormStyles();

    return (
        <form className={classes.root} onSubmit={onSubmit}>
            <h2 className={classes.title} style={{ color: procede === 'Deleteone' ? '#f00' : '' }}>{title}</h2>
            <Divider className={classes.divider} />
            <div className={classes.inputs}>
                {children}
            </div>
        </form>
    )
};