import axiosPost from '../axiosPost';

export const merchantsUpd = async (arg) => {
    const { id, nombre, cuit, plan, rubro, acreditacion, version } = arg;

    const body = {
        "service": "MerchantUpd",
        "params": {
            "merchantId": id,
            "merchantNombre": nombre,
            "merchantCUIT": cuit,
            "planNombre": plan,
            "rubroMerchantNombre": rubro,
            "merchantStatusAcreditacionesNombre": acreditacion,
            "merchantVersion": version,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}