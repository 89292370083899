import axiosPost from '../axiosPost';

export const formatosDeImagenUpd = async (arg) => {
    const { id, nombre, codigo, ancho, alto, version } = arg;

    const body = {
        "service": "FormatoDeImagenUpd",
        "params": {
            "formatoDeImagenId": id,
            "formatoDeImagenNombre": nombre,
            "formatoDeImagenCod": codigo,
            "formatoDeImagenAncho": ancho,
            "formatoDeImagenAlto": alto,
            "formatoDeImagenVersion": version,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
};