import React from 'react';
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '380px'
}

const MapContainer = props => {
    const {lat, lng} = props;
    return (
        lat && lng ?
            <Map google={props.google} zoom={14} containerStyle={containerStyle} initialCenter={{
                lat: -34.605613,
                lng: -58.3801271
            }} center={{lat, lng}}>
                <Marker position={{lat, lng}}/>
            </Map> :
            <Map google={props.google} zoom={14} containerStyle={containerStyle} initialCenter={{
                lat: -34.605613,
                lng: -58.3801271
            }}>
            </Map>
    )

}


export default GoogleApiWrapper({
    apiKey: 'AIzaSyA3CvTeFPuHo7ePjm5YOva66gR-pFWZvPc'
})(MapContainer);
