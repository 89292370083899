import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CircularIndeterminate(props) {
    return (        
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 300,
            fontSize: 50
        }} >
            <CircularProgress style={{fontSize:60}} />
        </div>
    );
}