import React, { useEffect, useState } from 'react'
import classes from './cuilcuit.module.css';
import Button from "@material-ui/core/Button";
import SubmitButton from '../../../components/utils/button/SubmitButton';
// Form
import { useFormik } from "formik";
import * as yup from "yup";
import MaskedInput from "react-text-mask";
// Servicios
import { endUserCuilCuitGet } from '../../../services/hooli-services/EndUser/EndUserCuilCuitGet';
import { endUserCuilCuitUpd } from '../../../services/hooli-services/EndUser/EndUserCuilCuitUpd';
// Recoil
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { userCredentials, rowSelected, snackbarData } from '../../../recoilState/GlobalState';

export default function CuilCuitModal(props) {
    const [cuit, setCuit] = useState('');
    const [inRequest, setInRequest] = useState(false)

    const credentials = useRecoilValue(userCredentials);
    const selected = useRecoilValue(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const { setProcede, updateModalList } = props.props

    useEffect(() => {
        const params = { "usuarioId": selected.row.id }

        const payload = { ...credentials, params };
        (async () => {
            const data = await endUserCuilCuitGet(payload)
            setCuit(data.usuarioCUIT)
        })()
    }, [credentials]);

    async function handleClick(cuit, codigo) {
        const params = {
            "usuarioId": selected.row.id,
            "estadoDeRegistroCod": codigo,
            "usuarioCUIT": cuit
        }

        const payload = { ...credentials, params };

        setInRequest(true);
        const response = await endUserCuilCuitUpd(payload);
        if (response) {
            if (response.status.code === 1) {
                setSnackbarInfo({
                    message: 'CUIT Confirmado',
                    severity: 'success',
                    open: true
                });
                setInRequest(false);
                setTimeout(() => {
                    setSnackbarInfo({
                        message: '',
                        severity: 'success',
                        open: false
                    });
                    setProcede(undefined);
                    updateModalList();
                }, 3000)
            }
            else {
                setSnackbarInfo({
                    message: response.status.errmsg,
                    severity: response.status.action === 'W' ? 'warning' : 'error',
                    open: true
                });
                setInRequest(false);
                setTimeout(() => {
                    setSnackbarInfo({
                        message: '',
                        severity: 'success',
                        open: false
                    });
                }, 3000);
            }
        }
    };

    function checkCuit(cuit) {
        const documentNumber = cuit.substring(0, cuit.length - 1).replaceAll('_', '').replaceAll('-', '').trim();

        let factor = 2;
        let resultado = 0;

        for (let i = documentNumber.length - 1; i >= 0; i--) {
            resultado = resultado + parseInt(documentNumber[i]) * factor;
            factor = (factor == 7) ? 2 : factor + 1;
        }

        const resto = resultado % 11;
        const control = 11 - resto;

        const cuitFormateado = cuit.replaceAll('_', '').replaceAll('-', '').trim()
        return control.toString() === cuitFormateado.substring(cuitFormateado.length - 1);
    };

    const validationSchema = yup.object({
        cuit: yup
            .string()
            .test('Control', "El ultimo numero es incorrecto", (value) => {
                if (value) return checkCuit(value)
                return
            })
            .required("Ingrese un cuit")
    })

    const formik = useFormik({
        initialValues: { cuit },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => handleClick(values.cuit, "A")
    });

    function mask(values) {
        const length = values.replaceAll('_', '').replaceAll('-', '').trim().length;
        return length === 10 ? [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/] : [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/];
    };

    return (
        <div className={classes.container}>
            <h2 className={classes.title}>Confirmar CUIT / CUIL</h2>
            <form onSubmit={formik.handleSubmit}>
                <label htmlFor="cuit" className={classes.label}>CUIT</label>
                <MaskedInput
                    name="cuit"
                    value={formik.values.cuit}
                    onChange={formik.handleChange}
                    mask={mask}
                    className={classes.input}
                    guide={false}
                    disabled={inRequest}
                />
                {formik.touched.cuit && formik.errors.cuit && <p>{formik.errors.cuit}</p>}
                <div className={classes.buttonRow}>
                    <Button disabled={inRequest} className={classes.button} onClick={() => window.open('https://www.anses.gob.ar/consulta/constancia-de-cuil', '_blank')}>
                        Consulta online
                    </Button>
                    <Button disabled={inRequest} className={classes.button} type="submit">
                        Confirmar
                    </Button>
                    <Button
                        disabled={inRequest} className={`${classes.button} ${classes.closeButton}`}
                        onClick={() => setProcede(undefined)}
                    >
                        <span>Cerrar</span>
                    </Button>
                </div>
            </form>
        </div >
    )
};