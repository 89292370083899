import React, { useState, useEffect } from "react";
// utils
import FormButton from "../../button/FormButton";
import { FormikTextInput } from '../../inputs/FormikInputs';
import CommonForm from "../CommonForm";
// functions
import { postFeriado, deleteFeriado, updateFeriado } from './FeriadosFunctions';
import { handleResponse } from "../../../../services/Local-services";
// validaciones
import { formikValidation } from './FeriadosFormValidaciones';
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userCredentials, formIsOpen, rowSelected, snackbarData, formData } from '../../../../recoilState/GlobalState'

export default function FeriadosForm(props) {
    const [newFeriado, setNewFeriado] = useState({});
    const [inRequest, setInRequest] = useState(false);

    const setIsOpen = useSetRecoilState(formIsOpen);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const [selected, setSelected] = useRecoilState(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const data = useRecoilValue(formData);

    const { titles, updateList, procede } = props.props;

    async function handleAction() {
        const functions = { setSnackbarInfo, setInRequest, setIsOpen, setSelected, updateList };
        if (procede === 'Deleteone') {
            const response = await deleteFeriado(credentials, selected.row, setInRequest);
            return handleResponse(response, 'Feriado eliminado exitosamente', functions)
        }
        if (procede === 'Addone') {
            const response = await postFeriado(credentials, newFeriado, setInRequest)
            return handleResponse(response, 'Feriado añadido exitosamente', functions)
        }
        if (procede === 'Editone') {
            const response = await updateFeriado(credentials, newFeriado, selected.row, setInRequest)
            return handleResponse(response, 'Feriado modificado exitosamente', functions)
        }
    };

    useEffect(() => {
        if (procede !== 'Deleteone') handleAction();
    }, [newFeriado])

    const formik = formikValidation(setNewFeriado, selected.row, titles);
    const disabled = procede === 'Deleteone' || inRequest;

    return (
        <CommonForm
            onSubmit={formik.handleSubmit}
            title={data.title}
            procede={procede}
        >
            <FormikTextInput
                data={data}
                name='nombre'
                formik={formik}
                disabled={disabled}
            />
            <FormButton
                inRequest={inRequest}
                handleAction={handleAction}
            />
        </CommonForm>
    );
};