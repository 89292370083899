import axiosPost from '../axiosPost';

export const merchantsIns = async (arg) => {
    const { nombre, cuit, plan, rubro, acreditacion } = arg;

    const body = {
        "service": "MerchantIns",
        "params": {
            "merchantNombre": nombre,
            "merchantCUIT": cuit,
            "planNombre": plan,
            "rubroMerchantNombre": rubro,
            "merchantStatusAcreditacionesNombre": acreditacion,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
};