import axiosPost from '../axiosPost';

export const tiposDeRegistroUpd = async (arg) => {
    const { id, nombre, codigo, version } = arg;

    const body = {
        "service": "TipoDeRegistroUpd",
        "params": {
            'tipoRegistroId': id,
            "tipoRegistroNombre": nombre,
            "tipoRegistroCod": codigo,
            "tipoRegistroVersion": version
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}