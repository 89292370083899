import React, { useState, useEffect } from "react";
// styles
import "../pages.css";
// components
import Filtros from './Filtros';
// utils
import XGridDemo from "../../components/utils/grid/Xgrid";
// consts
import { busquedaUsuarios as titles } from "../../consts/titulos-de-tablas";
//Assets
import CircularIndeterminate from '../../components/utils/spinner/Spinner';
//Services 
import { endUserListGet } from '../../services/hooli-services/EndUser/EndUserListGet';
// recoil
import { useRecoilValue } from 'recoil';
import { userCredentials } from '../../recoilState/GlobalState';

export default function BusquedaUsuarios() {
    const [users, setUsers] = useState([]);
    const [rows, setRows] = useState([]);
    const [filtros, setFiltros] = useState({apellido: '', documento: '', email: '', telefono: '', cvu: '', desde: '', hasta: new Date().toISOString().substring(0, 10)});
    const [cargando, setCargando] = useState(false);

    const credentials = useRecoilValue(userCredentials);

    async function retrieveEndUsers() {
        const params = {
            "usuarioApellidos": filtros.apellido,
            "usuarioNroDeDocumento": filtros.documento.replace(/\./g, ''),
            "usuarioMail": filtros.email,
            "usuarioTelefonoCelular": filtros.telefono,
            "usuarioCVU": filtros.cvu,
            "fechaDesde": filtros.desde,
            "fechaHasta": filtros.hasta
        }

        const payload = { ...credentials, params };
        setCargando(true);

        const data = await endUserListGet(payload)
        
        setRows([]);
        setCargando(false)
        setUsers(data)
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) retrieveEndUsers()
    }, [credentials, filtros]);

    useEffect(() => {
        if (users.length > 0) {
            users.map((item) => {
                const {
                    usuarioId,
                    usuarioApellidos,
                    usuarioNombres,
                    tipoDeDocumentoCod,
                    usuarioNroDeDocumento,
                    usuarioSexo,
                    usuarioCVU,
                    usuarioFechaAlta,
                    usuarioMail,
                    usuarioTelefonoCelular
                } = item;

                const users = {
                    id: usuarioId,
                    [titles[0]]: usuarioApellidos,
                    [titles[1]]: usuarioNombres,
                    [titles[2]]: tipoDeDocumentoCod,
                    [titles[3]]: usuarioNroDeDocumento.toLocaleString('es-ES'),
                    [titles[4]]: usuarioSexo,
                    [titles[5]]: usuarioMail,
                    [titles[6]]: usuarioTelefonoCelular,
                    [titles[7]]: usuarioCVU,
                    [titles[8]]: usuarioFechaAlta ? usuarioFechaAlta.substring(0, 10) : '',
                };

                setRows(rows => [...rows, users])
                return item
            })
        }
    }, [users]);

    return (
        <main className="container">
            <Filtros
                filtros={filtros}
                setFiltros={setFiltros}
                updateList={retrieveEndUsers}
                cargando={cargando}
            />
            <div className="row-grid">
                {cargando ? <CircularIndeterminate /> : <XGridDemo rows={rows} titles={titles} />}
            </div>
        </main>
    )
};