import axiosPost from '../axiosPost';

export const rubrosDeMerchantUpd = async (arg) => {
    const { id, nombre, codigo, agencia, domicilio, imagen, version } = arg;

    const body = {
        "service": "TipoDeRegistroUpd",
        "params": {
            'rubroMerchantId': id,
            "rubroMerchantNombre": nombre,
            "rubroMerchantCod": codigo,
            "rubroMerchantEsAgencia": agencia,
            "rubroMerchantADomicilio": domicilio,
            "imagePathRUBROM": imagen,
            "rubroMerchantVersion": version
        }
    };

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}