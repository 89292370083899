import axiosPost from '../axiosPost';

export const tiposDeDomicilioIns = async (arg) => {
    const { nombre, codigo } = arg;

    const body = {
        "service": "TipoDeDomicilioIns",
        "params": {
            "tipoDeDomicilioNombre": nombre,
            "tipoDeDomicilioCod": codigo,
        }
    }

    try {
        const result = await axiosPost('Y', body, arg)
        return result.data
    } catch (err) {
        console.log(err)
    }
}