// services
import { tiposDeRegistroIns } from '../../../../services/hooli-services/TiposDeRegistro/TiposDeRegistroIns';
import { tiposDeRegistroDel } from '../../../../services/hooli-services/TiposDeRegistro/TiposDeRegistroDel';
import { tiposDeRegistroUpd } from '../../../../services/hooli-services/TiposDeRegistro/TiposDeRegistroUpd';

export async function postTipoDeRegistro(credentials, newTipoDeRegistro, setInRequest) {
    if (Object.keys(newTipoDeRegistro).length > 0) {
        const payload = {...newTipoDeRegistro, ...credentials};

        setInRequest(true);
        const response = await tiposDeRegistroIns(payload);
        return response
    }
};

export async function deleteTipoDeRegistro(credentials, row, setInRequest) {
    const { id } = row

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await tiposDeRegistroDel(payload);
    return response
};

export async function updateTipoDeRegistro(credentials, newTipoDeRegistro, row, setInRequest) {
    if (Object.keys(newTipoDeRegistro).length > 0) {
        const { id, version } = row;

        const payload = {
            id,
            ...newTipoDeRegistro,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await tiposDeRegistroUpd(payload);
        return response
    }
};