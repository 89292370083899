// services
import { registrosIns } from '../../../../services/hooli-services/Registros/RegistrosIns';
import { registrosDel } from '../../../../services/hooli-services/Registros/RegistrosDel';
import { registrosUpd } from '../../../../services/hooli-services/Registros/RegistrosUpd';

export async function postAnRegistro(credentials, newRegistro,  setInRequest) {
    if (Object.keys(newRegistro).length > 0) {
        const payload = {...newRegistro, ...credentials};

        setInRequest(true);
        const response = await registrosIns(payload);
        return response
    }
};

export async function deleteRegistro(credentials, row, setInRequest) {
    const { id } = row;

    const payload = { ...credentials, id }

    setInRequest(true);
    const response = await registrosDel(payload)
    return response
};

export async function updateRegistro(credentials, newRegistro, row, setInRequest) {
    if (Object.keys(newRegistro).length > 0) {
        const { id, version } = row;
       const payload = {
            id,
            ...newRegistro,
            version,
            ...credentials
        };

        setInRequest(true);
        const response = await registrosUpd(payload);
        return response
    }
};